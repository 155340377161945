<form [formGroup]="form">
  <div class="control">
    <label>Navn</label>
    <input pInputText formControlName="navn" />
  </div>
  <div class="control">
    <label>Bruger id</label>
    <input pInputText formControlName="brugerId" />
  </div>
  <div class="control">
    <label>Registreringsnummer</label>
    <input pInputText formControlName="regnr" />
  </div>
  <div class="control">
    <div class="flex">
      <div style="width:200px">
        <label>Formuerådgiver</label>
      </div>
      <p-checkbox [binary]="true" formControlName="formue" />
    </div>
  </div>
  <div class="control">
    <div class="flex">
      <div style="width:200px">
        <label>LTS rådgiver</label>
      </div>
      <p-checkbox [binary]="true" formControlName="lts" />
    </div>
  </div>
  <div class="control">
    <div class="flex">
      <div style="width:200px">
        <label>FAL rettighed</label>
      </div>
      <p-checkbox [binary]="true" formControlName="fal" />
    </div>
  </div>
  <div class="flex gap-2">
    <p-button
      label="Søg"
      (click)="onClickSearch()"
      [loading]="raadgiverService.isLoading()"
      icon="pi pi-search"
      type="submit"
    />
    <p-button label="Nulstil" (click)="onClickReset()" icon="pi pi-times" severity="secondary" />
  </div>
</form>
@if (validationMessage()) {
  <p-message [text]="validationMessage()" severity="error" styleClass="mt-4" />
}

