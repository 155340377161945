import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ScrollPanel } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { AuthService } from '@core/auth/auth.service';
import { RettighedsType } from '@core/auth/rettighed.model';
import { Pensionsudbyder } from '../../pensionsudbyder.model';
import { InputText } from 'primeng/inputtext';
import dayjs from 'dayjs';
import { PensionsudbydereService } from '../../pensionsudbydere.service';
import { Button } from 'primeng/button';
import { PensionsudbyderHistorikService } from '../../pu-historik/pu-historik.service';
import { Dialog } from 'primeng/dialog';
import { Textarea } from 'primeng/textarea';
import { getDiffAsText } from '@core/form-utils';
import { BooleanPipe } from '@core/pipes/boolean.pipe';

@Component({
  selector: 'app-pu-detail-stamdata',
  imports: [
    BooleanPipe,
    ReactiveFormsModule,
    ScrollPanel,
    CheckboxModule,
    InputText,
    Button,
    Dialog,
    Textarea,
    FormsModule
  ],
  templateUrl: './pu-detail-stamdata.component.html',
  styleUrl: './pu-detail-stamdata.component.css'
})
export class PuDetailStamdataComponent {
  form: FormGroup;
  pu = input.required<Pensionsudbyder>();
  authService = inject(AuthService);
  puService = inject(PensionsudbydereService);
  puHistorikService = inject(PensionsudbyderHistorikService);

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      udbyderNavn: ['', { validators: [Validators.required] }],
      p41TilladtFraLeverandoer: [false, { validators: [Validators.required] }],
      inkluderIleverandoerliste: [false, { validators: [Validators.required] }],
      adresse: this.fb.group({
        id: [],
        conavn: [],
        bynavn: [],
        doer: [],
        postnr: [],
        etage: [],
        sted: [],
        vejnavn: [],
        vejnr: []
      })
    });

    effect(
      () => {
        const pu = this.pu();
        if (!pu) {
          this.form.reset();
          this.form.patchValue({
            oprettet: dayjs().toDate()
          });
        } else {
          this.form.setValue({

            udbyderNavn: pu.udbyderNavn || '',
            p41TilladtFraLeverandoer: pu.p41TilladtFraLeverandoer || '',
            inkluderIleverandoerliste: pu.inkluderIleverandoerliste || '',
            adresse: {
              id: pu.adresse?.id,
              conavn: pu.adresse?.conavn || '',
              bynavn: pu.adresse?.bynavn || '',
              doer: pu.adresse?.doer || '',
              postnr: pu.adresse?.postnr || '',
              etage: pu.adresse?.etage || '',
              sted: pu.adresse?.sted || '',
              vejnavn: pu.adresse?.vejnavn || '',
              vejnr: pu.adresse?.vejnr || ''
            }
          });
        }
      }
    );
  }

  allowEdit = computed(() => this.authService.hasRettighed(RettighedsType.AdminPensionsudbydereSkriv));

  saving = signal(false);
  showSaveDialog = signal(false);
  kommentar = signal('');
  saveDialogIsValid = computed(() => {
    return this.kommentar() && this.kommentar().length > 4;
  });


  onClickSave() {
    this.showSaveDialog.set(true);
  }

  async saveChanges() {
    this.saving.set(true);

    const pu = this.puService.selectedPu();
    const newData = {
      ...pu,
      ...this.form.value
    } as Pensionsudbyder;

    const systemKommentar = getDiffAsText(pu || {}, newData);

    if (pu?.id) {
      await this.puHistorikService.create({
        pensionsudbyderId: pu.id,
        kommentar: this.kommentar(),
        systemKommentar: systemKommentar
      });

      await this.puService.update(pu.id, newData);

      this.saving.set(false);
      this.showSaveDialog.set(false);
      this.kommentar.set('');

    }

  }


}
