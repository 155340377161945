<div class="flex flex-col h-full overflow-hidden">
  <div class="flex font-bold border-b border-gray-200 mb-2">
    <div class="w-96">Rolle</div>
    <div class="flex-1">Velkomstmail</div>
  </div>
  <p-scroll-panel class="flex-1 overflow-hidden" styleClass="h-full">
    @for (rolle of rolleData(); track rolle.id) {
      @if (rolle.tilladEkstern || isLetpension()) {
        <div class="flex items-center mb-1">
          <div class="w-96 flex items-center">
            <p-checkbox [binary]="true" [(ngModel)]="rolle.checked" (click)="grant(rolle)" />
            <label class="ml-2"> {{ rolle.navn }} </label>
            @if (rolle.beskrivelse) {
              <i class="pi pi-info-circle pl-1 !text-lg" [pTooltip]="rolle.beskrivelse" tooltipPosition="bottom"></i>
            }
          </div>
          <div class="flex-1">
            @if (rolle.brugerRolle?.velkomstmailAfsendt) {
              <div>
                Afsendt {{ rolle.brugerRolle?.velkomstmailAfsendt | date : 'dd-MM-YYYY HH:mm' }}
                @if (rolle.brugerRolle && !rolle.brugerRolle.loading) {
                  <span
                    class="send-mail-link"
                    (click)="sendMail(rolle.brugerRolle)"
                  >Gensend nu</span
                  >
                }
              </div>
            } @else {
              @if (rolle.velkomstmailMulig && brugerDetailService.changes()[rolle.id]?.action === 'add') {
                <div class="flex items-center">
                  <p-checkbox
                    [(ngModel)]="rolle.sendVelkomstMail"
                    [binary]="true"
                    (click)="bestilVelkomstMail(rolle)"
                  />
                  <span class="pl-2">Send velkomstmail</span>
                </div>
              }
              @if (rolle.velkomstmailMulig && rolle.brugerRolle && !rolle.brugerRolle.loading) {
                <div>
              <span
                class="send-mail-link"
                (click)="sendMail(rolle.brugerRolle)"
              >Afsend nu</span
              >
                </div>
              }

            }
          </div>
        </div>
      }
    }
  </p-scroll-panel>
</div>
