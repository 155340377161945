import { Injectable } from '@angular/core';
import { ApiSimpleService } from '@core/api/api.simple.service';
import { LogMessage } from './log.message.model';

@Injectable({
  providedIn: 'root',
})
export class LogDetailMessagesService extends ApiSimpleService<LogMessage> {
  constructor() {
    super('/api/logsearch/messages');
  }

  loadMessages(requestLogId: number) {
    this.load({ params: { requestLogId: requestLogId } });
  }
}
