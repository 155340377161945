import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-opkraevningslister',
    imports: [
        Message
    ],
    templateUrl: './opkraevningslister.component.html',
    styleUrl: './opkraevningslister.component.css'
})
export class OpkraevningslisterComponent {

}
