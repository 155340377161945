<div class="flex flex-col h-full">
  <div>
    <h1>Log</h1>
  </div>
  <p-tabs
    [(value)]="logService.activeTab"
    class="flex-1 border-1 overflow-hidden"
  >
    <p-tablist>
      <p-tab value="search"><i class="pi pi-search"></i> Søgning</p-tab>
      @if(logService.data().length>0){
      <p-tab value="result" (click)="onSearchResultClick()"
        ><i class="pi pi-list"></i> Resultat ({{
          logService.totalRowCount()
        }})</p-tab
      >
      } @if(logService.selectedLogEntry()){
      <p-tab value="detail"><i class="pi pi-file"></i> Detaljer</p-tab>
      } @if(logService.selectedLogEntry()?.ssbLogEntryId){
      <p-tab value="ssb"><i class="pi pi-external-link"></i> SSB</p-tab>
      }
    </p-tablist>
    <p-tabpanels class="overflow-hidden">
      <p-tabpanel value="search">
        <app-log-search />
      </p-tabpanel>
      <p-tabpanel value="result">
        <app-log-result />
      </p-tabpanel>
      <p-tabpanel value="detail">
        <app-log-detail />
      </p-tabpanel>
      <p-tabpanel value="ssb">
        <app-log-detail-ssb />
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>
</div>
