import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { PensionsudbydereService } from '../pensionsudbydere.service';
import { PuSearchParams } from './pu-search-params.model';
import { Button } from 'primeng/button';
import { InputText } from 'primeng/inputtext';
import { Message } from 'primeng/message';

@Component({
  selector: 'app-pu-search',
  imports: [ReactiveFormsModule, CheckboxModule, Button, InputText, Message],
  templateUrl: './pu-search.component.html',
  styleUrl: './pu-search.component.css'
})
export class PuSearchComponent {
  puService = inject(PensionsudbydereService);
  validationMessage = signal('');

  form = new FormGroup({
    navn: new FormControl(),
    regnr: new FormControl()
  });

  async onClickSearch() {
    this.validationMessage.set('');
    this.puService.puSearchParams = {
      ...(this.form.value as PuSearchParams)
    };

    await this.puService.searchPu();
    if (this.puService.data().length == 0) {
      this.validationMessage.set('Ingen hits');
    }
  }

  onClickReset() {
    this.form.setValue({
      navn: null,
      regnr: null
    });
  }
}
