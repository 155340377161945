import { Component, DestroyRef, inject, Injectable, signal, Type } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { SidenavLayoutComponent } from '../layout/sidenav/sidenav-layout/sidenav-layout.component';

@Injectable({
  providedIn: 'root'
})
export class RouteLayoutService {
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  private defaultLayout = SidenavLayoutComponent;

  private _layoutFromRouteData = signal<Type<Component>>(this.defaultLayout);
  layoutFromRouteData = this._layoutFromRouteData.asReadonly();

  constructor() {
    const subscription = this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        const layoutDefinedInRoute = data.state.root.firstChild?.data['layout'];
        if (layoutDefinedInRoute) {
          this._layoutFromRouteData.set(layoutDefinedInRoute);
        } else {
          this._layoutFromRouteData.set(this.defaultLayout);
        }
      }
    });
    this.destroyRef.onDestroy(() => subscription.unsubscribe());
  }

}

