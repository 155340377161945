@let pu = puService.selectedPu();
<div class="flex flex-col gap-2 h-full overflow-hidden">
  <div class="flex-1 overflow-hidden">
    @if (pu) {
      <p-tabs
        value="stamdata"
        class="h-full border-1 overflow-hidden"
      >
        <p-tablist>
          <p-tab value="stamdata">Stamdata</p-tab>
          <p-tab value="historik">Historik</p-tab>
        </p-tablist>
        <p-tabpanels class="overflow-hidden">
          <p-tabpanel value="stamdata">
            <app-pu-detail-stamdata [pu]="pu" />
          </p-tabpanel>
          <p-tabpanel value="historik">
            <app-pu-historik />
          </p-tabpanel>
        </p-tabpanels>
      </p-tabs>
    }
  </div>
</div>
