<form [formGroup]="form">
  <div class="control">
    <label>Navn</label>
    <input pInputText formControlName="navn" />
  </div>
  <div class="control">
    <label>IT Central</label>
    <p-select
      [options]="itcOptions"
      formControlName="itCentral"
      placeholder="Vælg ITC"
      [showClear]="true"
    />

  </div>
  <div class="control">
    <label>Reg nr.</label>
    <input pInputText formControlName="regnr" />
  </div>
  <div class="flex gap-2">
    <p-button
      label="Søg"
      (click)="onClickSearch()"
      [loading]="piService.isLoading()"
      icon="pi pi-search"
      type="submit"
    />
    <p-button label="Nulstil" (click)="onClickReset()" icon="pi pi-times" severity="secondary" />
  </div>
</form>
@if (validationMessage()) {
  <p-message [text]="validationMessage()" severity="error" styleClass="mt-4" />
}

