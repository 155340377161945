import { Injectable } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { PengeinstitutHistorik } from './pi-historik.model';

@Injectable({
  providedIn: 'root'
})
export class PengeinstitutHistorikService extends ApiRestService<PengeinstitutHistorik> {
  constructor() {
    super('/api/admin/pengeinstituthistorik');
  }
}
