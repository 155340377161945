import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-kunder',
    imports: [
        Message
    ],
    templateUrl: './kunder.component.html',
    styleUrl: './kunder.component.css'
})
export class KunderComponent {

}
