import { Injectable, signal } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { Raadgiver } from './raadgiver.model';
import { RaadgiverSearchParams } from './raadgiver-search/raadgiver-search-params.model';

@Injectable({
  providedIn: 'root'
})
export class RaadgivereService extends ApiRestService<Raadgiver> {
  constructor() {
    super('/api/admin/raadgivere');
  }


  selectedRaadgiver = signal<Raadgiver | undefined>(undefined);

  activeTab = signal('search');
  mode = signal('update');
  private scrollPosition = 0;
  private readonly LOG_TABLE_QUERY_SELECTOR =
    '#raadgiverResultTable .p-datatable-table-container';

  raadgiverSearchParams: RaadgiverSearchParams = {};

  async searchRaadgiver() {
    this.scrollPosition = 0;
    this.selectedRaadgiver.set(undefined);
    await this.load({
      params: {
        filter: JSON.stringify(this.raadgiverSearchParams)
      }
    });
    if (this.data().length > 0) {
      this.activeTab.set('result');
    }
  }

  opretRaadgiver() {
    this.mode.set('create');
    this.selectedRaadgiver.set(undefined);
    this.activeTab.set('detail');
  }

  selectRaadgiverResultRow(raadgiver: Raadgiver) {
    this.mode.set('update');
    this.selectRaadgiver(raadgiver);

    if (raadgiver) {
      this.activeTab.set('detail');
    }
    this.saveRaadgiverResultScrollPosition();
  }

  selectRaadgiver(raadgiver: Raadgiver) {
    this.selectedRaadgiver.set(raadgiver);

  }

  saveRaadgiverResultScrollPosition() {
    const tbl = document.querySelector(this.LOG_TABLE_QUERY_SELECTOR);
    if (tbl) {
      this.scrollPosition = tbl.scrollTop;
    }
  }

  restoreRaadgiverResultScrollPosition() {
    setTimeout(() => {
      const tbl = document.querySelector(this.LOG_TABLE_QUERY_SELECTOR);
      if (tbl) {
        tbl?.scrollTo({ top: this.scrollPosition });
      }
    }, 1);
  }
}
