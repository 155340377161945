<p-table
  [value]="logDetailCallsService.data()"
  [loading]="logDetailCallsService.isLoading()"
  [showGridlines]="true"
  stripedRows
  class="p-datatable-sm lp-table"
  dataKey="id"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Id</th>
      <th>Metode</th>
      <th>System</th>
      <th>Tidsforbrug</th>
      <th>Fejlet</th>
    </tr>
  </ng-template>
  <ng-template
    [appTableRow]="logDetailCallsService.data()"
    pTemplate="body"
    let-item
  >
    <tr
      class="cursor-pointer"
      [class]="rowClass(item)"
      (click)="onRowClick(item)"
    >
      <td>{{ item.id }}</td>
      <td>{{ item.metodeNavn }}</td>
      <td>{{ item.system }}</td>
      <td class="!text-right">{{ item.tidsforbrug | number }} ms</td>
      <td>{{ item.fejlet }}</td>
    </tr>
  </ng-template>
</p-table>
