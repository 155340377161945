import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import {
  ApplicationConfig,
  ErrorHandler,
  inject,
  LOCALE_ID,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core';
import { provideRouter, Router, withComponentInputBinding } from '@angular/router';
import { routes } from '../app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { appConfig } from './app-config';
import { authTokenInterceptor } from './auth/token.interceptor';
import { providePrimeNG } from 'primeng/config';
import { LetpensionPreset } from './primevue/letpensionPreset';
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import { da } from './primevue/locales/da.json';

export function initAngular() {
  const angularConfig: ApplicationConfig = {
    providers: [
      { provide: LOCALE_ID, useValue: 'da' },
      provideZoneChangeDetection({ eventCoalescing: true }),
      provideRouter(routes, withComponentInputBinding()),
      provideAnimationsAsync(),
      provideHttpClient(withInterceptors([authTokenInterceptor])),
      providePrimeNG({
        translation: da,
        theme: {
          preset: LetpensionPreset,
          options: {
            darkModeSelector: 'none',
          },
        },
      }),
    ],
  };

  if (appConfig.sentry.enabled) {
    angularConfig.providers.push(
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler(),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      provideAppInitializer(() => {
        inject(Sentry.TraceService);
      })
    );
  }

  registerLocaleData(localeDa);

  bootstrapApplication(AppComponent, angularConfig).catch((err) =>
    console.error(err)
  );
}
