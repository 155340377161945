import { Component, computed, inject, signal } from '@angular/core';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { RouterLink } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
    selector: 'app-sidebar',
    imports: [
        SideMenuComponent,
        RouterLink,
        ScrollPanelModule
    ],
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.css'
})
export class SidebarComponent {
  authService = inject(AuthService);
  currentUser = computed(() => this.authService.state().bruger);
  collapsed = signal<boolean>(false);

  onSidebarClick() {
    this.collapsed.set(!this.collapsed());

  }
}
