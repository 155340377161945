import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-ssb-konfiguration',
    imports: [
        Message
    ],
    templateUrl: './ssb-konfiguration.component.html',
    styleUrl: './ssb-konfiguration.component.css'
})
export class SsbKonfigurationComponent {

}
