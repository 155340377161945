<main class="h-full">
  @if (authStatus() === AuthStatus.AUTHORIZED) {
    @if (routeAllowed()) {
      <ng-container *ngComponentOutlet="layoutComponent()">
        <router-outlet></router-outlet>
      </ng-container>
    } @else {
      <app-access-denied />
    }
  } @else {
    <app-login />
  }
</main>
