<p-table
  id="puResultTable"
  [value]="puService.data()"
  [loading]="puService.isLoading()"
  [showGridlines]="true"
  [scrollable]="true"
  scrollHeight="flex"
  stripedRows
  class="p-datatable-sm lp-table"
  selectionMode="single"
  [(selection)]="puService.selectedPu"
  (onRowSelect)="onRowSelect($event)"
  dataKey="id"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="udbyderNavn">Navn
        <p-sortIcon field="udbyderNavn" />
      </th>
      <th pSortableColumn="pengeinstitut.regnr" style="width: 120px">Regnr
        <p-sortIcon field="pengeinstitut.regnr" />
      </th>
      <th pSortableColumn="pensionsInfoUdbyderNavnNr" style="width: 120px">NavnNr
        <p-sortIcon field="pensionsInfoUdbyderNavnNr" />
      </th>
      <th pSortableColumn="p41TilladtFraLeverandoer" style="width: 140px">§41 tilladt
        <p-sortIcon field="p41TilladtFraLeverandoer" />
      </th>
    </tr>
  </ng-template>
  <ng-template [appTableRow]="puService.data()" pTemplate="body" let-item>
    <tr [pSelectableRow]="item" class="cursor-pointer">
      <td>{{ item.udbyderNavn }}</td>
      <td>{{ item.pengeinstitut?.regnr }}</td>
      <td>{{ item.pensionsInfoUdbyderNavnNr }}</td>
      <td>
        @if (item.p41TilladtFraLeverandoer) {
          <i class="pi pi-check"></i>
        }
      </td>

    </tr>
  </ng-template>
</p-table>
