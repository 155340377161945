<div class="flex flex-col h-full">
  <p-toolbar>
    <ng-template pTemplate="start">
      <p-button routerLink="/brugeradm/roller" [queryParams]="{id:-1}" icon="pi pi-plus" label="Opret rolle"
                class="mr-2"
      />
    </ng-template>
    <ng-template pTemplate="end">
    </ng-template>
  </p-toolbar>
  <p-table [value]="roller()"
           showGridlines
           stripedRows
           [scrollable]="true"
           scrollHeight="flex"
           [loading]="rollerService.isLoading()"
           class="flex-1 overflow-hidden p-datatable-sm mt-4">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="navn" style="width: 300px">
          Navn
          <p-sortIcon field="navn" />
        </th>
        <th pSortableColumn="beskrivelse">
          Beskrivelse
          <p-sortIcon field="beskrivelse" />
        </th>
      </tr>
    </ng-template>
    <ng-template [appTableRow]="roller()" pTemplate="body" let-rolle>
      <tr [routerLink]="['/brugeradm', 'roller']" [queryParams]="{id:rolle.id}" class="cursor-pointer">
        <td>{{ rolle.navn }}</td>
        <td>{{ rolle.beskrivelse }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
