import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { BrugerNotifikation } from './bruger-notifikationer.model';
import { ApiResponse } from '@core/api/apiresponse.model';
import { appConfig } from '@core/app-config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrugerNotifikationerService {

  private destroyRef = inject(DestroyRef);
  private httpClient = inject(HttpClient);

  data = signal<BrugerNotifikation[]>([]);
  _loading = signal(false);
  readonly isLoading = this._loading.asReadonly();
  _failed = signal(false);
  readonly isFailed = this._failed.asReadonly();
  _error = signal<unknown>(undefined);
  readonly error = this._error.asReadonly();


  load(brugerId: number) {
    this._loading.set(true);
    this._failed.set(true);

    const subscription = this.httpClient.get<ApiResponse<BrugerNotifikation[]>>(`${appConfig.apiRoot}/v2/BrugerNotifikationer/bruger/${brugerId}`).subscribe({
      next: (response) => {
        const sortedData = response.data.sort((a, b) =>
          a.notifikationType.navn > b.notifikationType.navn
            ? 1
            : b.notifikationType.navn > a.notifikationType.navn
              ? -1
              : 0
        );

        this.data.set(sortedData);
      },
      error: err => {
        //TODO Handle error getting brugerauthorization
        this._error.set(err);
        this._failed.set(true);
        console.error(err);
      },
      complete: () => {
        this._loading.set(false);
      }
    });

    this.destroyRef.onDestroy(() => subscription.unsubscribe());
  }


}
