import { Injectable } from '@angular/core';
import { ApiSimpleService } from '@core/api/api.simple.service';

@Injectable({
  providedIn: 'root',
})
export class LogSearchMaskinerService extends ApiSimpleService<string> {
  constructor() {
    super('/api/logsearch/maskiner');
    super.load();
  }
}
