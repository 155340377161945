import { Component, forwardRef, inject, signal } from '@angular/core';
import { SelectModule } from 'primeng/select';
import { PengeinstitutterService } from '@features/pengeinstitutter/pengeinstitutter.service';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-pengeinstitut-select',
  imports: [SelectModule, FormsModule],
  templateUrl: './pengeinstitut-select.component.html',
  styleUrl: './pengeinstitut-select.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PengeinstitutSelectComponent),
      multi: true
    }
  ]
})
export class PengeinstitutSelectComponent implements ControlValueAccessor {
  piService = inject(PengeinstitutterService);
  valueSignal = signal<number | undefined>(undefined);
  isDisabled: boolean = false;

  private onChange: any = () => {
  };
  private onTouch: any = () => {
  };

  constructor() {
    if (!this.piService.isLoaded()) {
      this.piService.load().then();
    }
  }

  writeValue(value: number): void {
    this.valueSignal.set(value); // Update the signal when the value changes
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onValueChange(newValue: any): void {
    this.valueSignal.set(newValue);
    this.onChange(newValue);
    this.onTouch();
  }
}
