import { Component, computed, effect, inject, signal } from '@angular/core';
import { PengeinstitutterService } from '../pengeinstitutter.service';
import { PengeinstitutterIndstillingerService } from '../pi-indstilling/pengeinstitutindstillinger.service';
import { PengeinstitutIndstilling } from '../pi-indstilling/pengeinstitutindstilling.model';
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PiHistorikComponent } from '../pi-historik/pi-historik.component';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'primeng/tabs';
import { PiDetailStamdataComponent } from './pi-detail-stamdata/pi-detail-stamdata.component';
import { PiDetailIndstillingerComponent } from './pi-detail-indstillinger/pi-detail-indstillinger.component';
import {
  PiDetailIndbetalingsaftaleComponent
} from './pi-detail-indbetalingsaftale/pi-detail-indbetalingsaftale.component';
import { PiDetailInvesteringsvalgComponent } from './pi-detail-investeringsvalg/pi-detail-investeringsvalg.component';
import { PiDetailBegunstigelseComponent } from './pi-detail-begunstigelse/pi-detail-begunstigelse.component';
import { PiDetailOverloebComponent } from './pi-detail-overloeb/pi-detail-overloeb.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { PengeinstitutHistorikService } from '../pi-historik/pi-historik.service';
import { DialogModule } from 'primeng/dialog';
import { TextareaModule } from 'primeng/textarea';
import { Pengeinstitut } from '../pengeinstitut.model';
import { RettighedsType } from '@core/auth/rettighed.model';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-pi-detail',
  imports: [AccordionModule, ScrollPanelModule, PiHistorikComponent, Tab, TabList, TabPanel, TabPanels, Tabs,
    PiDetailStamdataComponent, PiDetailIndstillingerComponent, PiDetailIndbetalingsaftaleComponent,
    PiDetailInvesteringsvalgComponent, PiDetailBegunstigelseComponent, PiDetailOverloebComponent, ReactiveFormsModule, ButtonModule, DialogModule, TextareaModule, FormsModule],
  templateUrl: './pi-detail.component.html',
  styleUrl: './pi-detail.component.css'
})
export class PiDetailComponent {
  piService = inject(PengeinstitutterService);
  piIndstillingerService = inject((PengeinstitutterIndstillingerService));
  piHistorikService = inject(PengeinstitutHistorikService);
  authService = inject(AuthService);
  allowEdit = computed(() => this.authService.hasRettighed(RettighedsType.AdminPengeinstitutterSkriv));

  constructor() {
    effect(
      () => {
        const pi = this.piService.selectedPi();
        if (!pi) {
          return;
        }

        this.form.setValue({
          inkluderIpiskifteListe: pi.inkluderIpiskifteListe
        });
      }
    );
  }

  saving = signal(false);
  showSaveDialog = signal(false);
  kommentar = signal('');
  saveDialogIsValid = computed(() => {
    return this.kommentar() && this.kommentar().length > 4;
  });

  indstillinger = computed(() => {
    if (this.piIndstillingerService.data().length > 0) {
      return this.piIndstillingerService.data()[0];
    }
    return {} as PengeinstitutIndstilling;
  });

  form = new FormGroup({
    inkluderIpiskifteListe: new FormControl()
  });

  onClickSave() {
    this.showSaveDialog.set(true);
  }

  async saveChanges() {
    this.saving.set(true);
    const systemKommentar = 'InkluderIPiskifteliste : ' + this.piService.selectedPi()?.inkluderIpiskifteListe + ' => ' + this.form.value.inkluderIpiskifteListe;

    const pi = this.piService.selectedPi();
    if (pi?.id) {
      await this.piHistorikService.create({
        pengeinstitutId: pi.id,
        kommentar: this.kommentar(),
        systemKommentar: systemKommentar
      });

      const newData: Pengeinstitut = {
        ...pi,
        inkluderIpiskifteListe: !!this.form.value.inkluderIpiskifteListe
      };
      await this.piService.update(pi.id, newData);

      this.saving.set(false);
      this.showSaveDialog.set(false);
      this.kommentar.set('');

    }

  }


}
