import { Component, effect, inject, input, untracked } from '@angular/core';
import { BrugerNotifikationerService } from './bruger-notifikationer.service';

@Component({
  selector: 'app-bruger-notifikationer',
  imports: [],
  templateUrl: './bruger-notifikationer.component.html',
  styleUrl: './bruger-notifikationer.component.css'
})
export class BrugerNotifikationerComponent {
  brugerId = input.required<number>();

  brugerNotifikationerService = inject(BrugerNotifikationerService);

  constructor() {
    effect(async () => {
      if (this.brugerId() > 0) {
        await untracked(async () => {
          this.brugerNotifikationerService.load(this.brugerId());
        });
      }
    });
  }

}
