import { Component, inject } from '@angular/core';
import { PengeinstitutHistorikService } from './pi-historik.service';
import { DatePipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';

@Component({
  selector: 'app-pi-historik',
  imports: [
    DatePipe,
    TableModule,
    TableRowDirective
  ],
  templateUrl: './pi-historik.component.html',
  styleUrl: './pi-historik.component.css'
})
export class PiHistorikComponent {
  piHistorikService = inject(PengeinstitutHistorikService);

}
