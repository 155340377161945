import { Injectable } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { BrugerHistorik } from './bruger-historik.model';

@Injectable({
  providedIn: 'root'
})
export class BrugerHistorikService extends ApiRestService<BrugerHistorik> {

  constructor() {
    super('/v2/brugerhistorik');
  }

}
