import { Component, computed, inject, Input, input } from '@angular/core';
import { BooleanPipe } from '@core/pipes/boolean.pipe';
import { DatePipe } from '@angular/common';
import { Pengeinstitut } from '../../pengeinstitut.model';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ScrollPanel } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { AuthService } from '@core/auth/auth.service';
import { RettighedsType } from '@core/auth/rettighed.model';

@Component({
  selector: 'app-pi-detail-stamdata',
  imports: [
    BooleanPipe,
    DatePipe,
    ReactiveFormsModule,
    ScrollPanel,
    CheckboxModule
  ],
  templateUrl: './pi-detail-stamdata.component.html',
  styleUrl: './pi-detail-stamdata.component.css'
})
export class PiDetailStamdataComponent {
  pi = input.required<Pengeinstitut>();
  authService = inject(AuthService);
  @Input() formGroup!: FormGroup;
  allowEdit = computed(() => this.authService.hasRettighed(RettighedsType.AdminPengeinstitutterSkriv));

}
