import { Injectable } from '@angular/core';
import { Bruger } from './bruger.model';
import { ApiRestService } from '@core/api/api.rest.service';

@Injectable({
  providedIn: 'root'
})
export class BrugerService extends ApiRestService<Bruger> {

  constructor() {
    super('/v2/brugere');
    this.load({ params: { sortBy: 'fornavn' } });
  }

}

