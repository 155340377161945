export * from 'primeng/icons/angledoubledown';
export * from 'primeng/icons/angledoubleleft';
export * from 'primeng/icons/angledoubleright';
export * from 'primeng/icons/angledoubleup';
export * from 'primeng/icons/angledown';
export * from 'primeng/icons/angleleft';
export * from 'primeng/icons/angleright';
export * from 'primeng/icons/angleup';
export * from 'primeng/icons/arrowdown';
export * from 'primeng/icons/arrowdownleft';
export * from 'primeng/icons/arrowdownright';
export * from 'primeng/icons/arrowleft';
export * from 'primeng/icons/arrowright';
export * from 'primeng/icons/arrowup';
export * from 'primeng/icons/ban';
export * from 'primeng/icons/bars';
export * from 'primeng/icons/blank';
export * from 'primeng/icons/calendar';
export * from 'primeng/icons/caretleft';
export * from 'primeng/icons/caretright';
export * from 'primeng/icons/check';
export * from 'primeng/icons/chevrondown';
export * from 'primeng/icons/chevronleft';
export * from 'primeng/icons/chevronright';
export * from 'primeng/icons/chevronup';
export * from 'primeng/icons/exclamationtriangle';
export * from 'primeng/icons/eye';
export * from 'primeng/icons/eyeslash';
export * from 'primeng/icons/filter';
export * from 'primeng/icons/filterslash';
export * from 'primeng/icons/home';
export * from 'primeng/icons/infocircle';
export * from 'primeng/icons/minus';
export * from 'primeng/icons/pencil';
export * from 'primeng/icons/plus';
export * from 'primeng/icons/refresh';
export * from 'primeng/icons/search';
export * from 'primeng/icons/searchminus';
export * from 'primeng/icons/searchplus';
export * from 'primeng/icons/sortalt';
export * from 'primeng/icons/sortamountdown';
export * from 'primeng/icons/sortamountupalt';
export * from 'primeng/icons/spinner';
export * from 'primeng/icons/star';
export * from 'primeng/icons/starfill';
export * from 'primeng/icons/thlarge';
export * from 'primeng/icons/times';
export * from 'primeng/icons/timescircle';
export * from 'primeng/icons/trash';
export * from 'primeng/icons/undo';
export * from 'primeng/icons/upload';
export * from 'primeng/icons/windowmaximize';
export * from 'primeng/icons/windowminimize';

/**
 * @file
 * THIS FILE IS AUTO-GENERATED. PLEASE DO NOT MODIFY.
 */

/**
 * Generated bundle index. Do not edit.
 */
