<div class="h-full p-2">
  <p-scroll-panel [style]="{ width: '100%', height: '100%' }">
    @for (rettighed of rettighedData(); track rettighed.name) {
      <div class="flex mb-1">
        <p-checkbox name="group" [binary]="true" [(ngModel)]="rettighed.checked" (click)="grant(rettighed)" />
        <label class="ml-2"> {{ rettighed.description }} <span class="rettighed-name">[{{ rettighed.name }}]</span>
        </label>
      </div>
    }
  </p-scroll-panel>
</div>
