<p-table
  [value]="logDetailMessagesService.data()"
  [loading]="logDetailMessagesService.isLoading()"
  [showGridlines]="true"
  stripedRows
  class="p-datatable-sm lp-table"
  dataKey="id"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 300px">Tidspunkt</th>
      <th>Niveau</th>
      <th>Besked</th>
    </tr>
  </ng-template>
  <ng-template
    [appTableRow]="logDetailMessagesService.data()"
    pTemplate="body"
    let-item
  >
    <tr class="cursor-pointer">
      <td class="align-top text-nowrap">
        {{ item.tidspunkt | date : "dd-MM-YYYY HH:mm:ss" }}
      </td>
      <td class="align-top">{{ item.alvorlighedsgrad }}</td>
      <td>
        <pre>{{ item.besked }}</pre>
      </td>
    </tr>
  </ng-template>
</p-table>
