import { Component, computed, inject, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AuthService } from '../auth.service';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { SystemPagecomponent } from '../../pages/system-page/system-pagecomponent';
import { AuthStatus } from '../auth.model';

@Component({
  selector: 'app-login',
  imports: [ButtonModule, InputTextModule, FormsModule, SystemPagecomponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  authService = inject(AuthService);
  authStatus = computed(() => this.authService.state().status);
  defaultUsername = window.localStorage.getItem('recent_username') || '';
  username = signal(this.defaultUsername);

  usernameIsValid = computed(() => {
    const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    return emailPattern.test(this.username());
  });

  async onClickLogin() {
    if (this.usernameIsValid()) {
      window.localStorage.setItem('recent_username', this.username());
      await this.authService.signIn(this.username());
    }
  }

  protected readonly AuthStatus = AuthStatus;
}
