import { inject, Injectable, signal } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { Pensionsudbyder } from './pensionsudbyder.model';
import { PuSearchParams } from './pu-search/pu-search-params.model';
import { PensionsudbyderHistorikService } from './pu-historik/pu-historik.service';

@Injectable({
  providedIn: 'root'
})
export class PensionsudbydereService extends ApiRestService<Pensionsudbyder> {
  constructor() {
    super('/api/admin/pensionsudbydere');
  }

  puHistorikService = inject((PensionsudbyderHistorikService));

  selectedPu = signal<Pensionsudbyder | undefined>(undefined);

  activeTab = signal('search');
  private scrollPosition = 0;
  private readonly LOG_TABLE_QUERY_SELECTOR =
    '#puResultTable .p-datatable-table-container';

  puSearchParams: PuSearchParams = {};

  async searchPu() {
    this.scrollPosition = 0;
    this.selectedPu.set(undefined);
    await this.load({
      params: {
        filter: JSON.stringify(this.puSearchParams),
        sortBy: 'udbyderNavn'
      }
    });
    if (this.data().length > 0) {
      this.activeTab.set('result');
    }
  }

  selectPuResultRow(pensionsudbyder: Pensionsudbyder) {
    this.selectPensionsudbyder(pensionsudbyder);

    if (pensionsudbyder) {
      this.activeTab.set('detail');
    }
    this.savePuResultScrollPosition();
  }

  selectPensionsudbyder(pensionsudbyder: Pensionsudbyder) {
    this.selectedPu.set(pensionsudbyder);

    this.puHistorikService.load({
      params: {
        filter: JSON.stringify({
          pensionsudbyderId: pensionsudbyder.id
        }),
        sortBy: 'tidspunkt',
        descending: true
      }
    }).then();
  }

  savePuResultScrollPosition() {
    const tbl = document.querySelector(this.LOG_TABLE_QUERY_SELECTOR);
    if (tbl) {
      this.scrollPosition = tbl.scrollTop;
    }
  }

  restorePuResultScrollPosition() {
    setTimeout(() => {
      const tbl = document.querySelector(this.LOG_TABLE_QUERY_SELECTOR);
      if (tbl) {
        tbl?.scrollTo({ top: this.scrollPosition });
      }
    }, 1);
  }
}
