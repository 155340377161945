import { inject, Injectable, signal } from '@angular/core';
import { Bruger } from '../bruger.model';
import { ApiResponse } from '@core/api/apiresponse.model';
import { BrugerService } from '../bruger.service';
import { BrugerRollerService } from '../bruger-roller/bruger-roller.service';

@Injectable({
  providedIn: 'root'
})
export class BrugerDetailService {
  brugerService = inject(BrugerService);
  brugerRolleService = inject(BrugerRollerService);

  changes = signal<{ [key: number]: any }>({});

  async saveBruger(id: number, newData: Bruger) {
    let response: ApiResponse<Bruger>;
    if (id > 0) {
      response = await this.brugerService.update(id, newData);
    } else {
      response = await this.brugerService.create(newData);
    }
    return response;
  }

  saveBrugerRoller(brugerId: number) {
    const promises = [];
    for (const [rolleId, change] of Object.entries(this.changes())) {
      if (change.action === 'remove') {
        promises.push(this.brugerRolleService.remove(change.id));
      }
      if (change.action === 'add') {
        promises.push(
          this.brugerRolleService.create({
            brugerId: brugerId,
            rolleId: parseInt(rolleId),
            sendVelkomstmail: change.sendVelkomstmail
          })
        );
      }
    }
    this.changes.set([]);
    return Promise.all(promises);
  }


}

