import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-risikoopkraevning',
    imports: [
        Message
    ],
    templateUrl: './risikoopkraevning.component.html',
    styleUrl: './risikoopkraevning.component.css'
})
export class RisikoopkraevningComponent {

}
