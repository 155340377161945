import { Injectable } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { PensionsudbyderHistorik } from './pu-historik.model';

@Injectable({
  providedIn: 'root'
})
export class PensionsudbyderHistorikService extends ApiRestService<PensionsudbyderHistorik> {
  constructor() {
    super('/api/admin/pensionsudbyderhistorik');
  }
}
