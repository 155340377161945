import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { appConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class RouteTitleService {
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  private defaultTitle = appConfig.appName;
  private _titleFromRoute = signal(this.defaultTitle);
  titleFromRoute = this._titleFromRoute.asReadonly();

  constructor() {
    const subscription = this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        const titleDefinedInRoute = data.state.root.firstChild?.data['pageTitle'];
        if (titleDefinedInRoute) {
          this._titleFromRoute.set(titleDefinedInRoute);
        } else {
          this._titleFromRoute.set(this.defaultTitle);
        }
      }
    });
    this.destroyRef.onDestroy(() => subscription.unsubscribe());
  }

}

