import { Injectable } from '@angular/core';
import { ApiSimpleService } from '@core/api/api.simple.service';

@Injectable({
  providedIn: 'root',
})
export class LogSearchSystemerService extends ApiSimpleService<string> {
  constructor() {
    super('/api/logsearch/systemer');
    super.load();
  }
}
