import { DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { LogService } from '../log.service';
import { LogDetailCallsComponent } from '../log-detail-calls/log-detail-calls.component';
import { DividerModule } from 'primeng/divider';
import { LogDetailMessagesComponent } from '../log-detail-messages/log-detail-messages.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'app-log-detail',
  imports: [
    DatePipe,
    DividerModule,
    LogDetailCallsComponent,
    LogDetailMessagesComponent,
    ScrollPanelModule,
  ],
  templateUrl: './log-detail.component.html',
  styleUrl: './log-detail.component.css',
})
export class LogDetailComponent {
  logService = inject(LogService);
}
