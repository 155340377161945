<div class="flex flex-col h-full">
  <p-toolbar>
    <ng-template pTemplate="start">
      <p-button
        routerLink="/brugeradm/brugere"
        icon="pi pi-arrow-left"
        label="Tilbage til listen"
        class="mr-2"
        text
        severity="secondary"
      />
    </ng-template>
    <ng-template pTemplate="end">
      <p-button
        label="Gem bruger"
        (click)="onClickSave()"
        [loading]="brugerService.isLoading()"
      />
    </ng-template>
  </p-toolbar>
  <div class="flex-1 flex flex-wrap gap-4 mt-4 overflow-hidden">
    <p-panel styleClass="w-full w-[400px] h-full">
      <form [formGroup]="form">
        <div class="control">
          <label>Fornavn</label>
          <input pInputText autofocus formControlName="fornavn" />
          @if (fornavnIsInvalid) {
          <p class="error">Fornavn er påkrævet</p>
          }
        </div>
        <div class="control">
          <label>Efternavn</label>
          <input pInputText formControlName="efternavn" />
          @if (efternavnIsInvalid) {
          <p class="error">Efternavn er påkrævet</p>
          }
        </div>
        <div class="control">
          <label>Email</label>
          <input pInputText formControlName="email" />
          @if (emailIsInvalid) {
          <p class="error">Email er ikke gyldig</p>
          }
        </div>
        @if (validationMessage()) {
        <p-message [text]="validationMessage()" severity="error" />
        }
      </form>
    </p-panel>
    <div class="w-full 2xl:flex-1 border-1 rounded max-h-full overflow-hidden">
      <p-tabs value="0" class="h-full overflow-hidden">
        <p-tablist>
          <p-tab value="0"><i class="pi pi-user"></i> Roller</p-tab>
          <p-tab value="1"><i class="pi pi-envelope"></i> Notifikationer</p-tab>
          <p-tab value="2"><i class="pi pi-list"></i> Historik</p-tab>
        </p-tablist>
        <p-tabpanels class="h-full overflow-hidden">
          <p-tabpanel value="0" class="h-full overflow-hidden">
            <app-bruger-roller
              [brugerId]="id()"
              [isLetpension]="isLetpension"
            />
          </p-tabpanel>
          <p-tabpanel value="1">
            <app-bruger-notifikationer [brugerId]="id()" />
          </p-tabpanel>
          <p-tabpanel value="2">
            <app-bruger-historik [brugerId]="id()" />
          </p-tabpanel>
        </p-tabpanels>
      </p-tabs>
    </div>
  </div>
</div>
