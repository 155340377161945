import { inject, Injectable, signal } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { Pengeinstitut } from './pengeinstitut.model';
import { PiSearchParams } from './pi-search/pi-search-params.model';
import { PengeinstitutterIndstillingerService } from './pi-indstilling/pengeinstitutindstillinger.service';
import { PengeinstitutHistorikService } from './pi-historik/pi-historik.service';

@Injectable({
  providedIn: 'root'
})
export class PengeinstitutterService extends ApiRestService<Pengeinstitut> {
  constructor() {
    super('/api/admin/pengeinstitutter');
  }

  piIndstillingerService = inject((PengeinstitutterIndstillingerService));
  piHistorikService = inject((PengeinstitutHistorikService));

  selectedPi = signal<Pengeinstitut | undefined>(undefined);

  activeTab = signal('search');
  private scrollPosition = 0;
  private readonly LOG_TABLE_QUERY_SELECTOR =
    '#piResultTable .p-datatable-table-container';

  piSearchParams: PiSearchParams = {};

  async searchPi() {
    this.scrollPosition = 0;
    this.selectedPi.set(undefined);
    await this.load({
      params: {
        filter: JSON.stringify(this.piSearchParams),
        sortBy: 'navn'
      }
    });
    if (this.data().length > 0) {
      this.activeTab.set('result');
    }
  }

  selectPiResultRow(pengeinstitut: Pengeinstitut) {
    this.selectPengeinstitut(pengeinstitut);

    if (pengeinstitut) {
      this.activeTab.set('detail');
    }
    this.savePiResultScrollPosition();
  }

  selectPengeinstitut(pengeinstitut: Pengeinstitut) {
    this.selectedPi.set(pengeinstitut);

    this.piIndstillingerService.load({
      params: {
        filter: JSON.stringify({
          pengeinstitutId: pengeinstitut.id
        })
      }
    }).then();

    this.piHistorikService.load({
      params: {
        filter: JSON.stringify({
          pengeinstitutId: pengeinstitut.id
        }),
        sortBy: 'tidspunkt',
        descending: true
      }
    }).then();
  }

  savePiResultScrollPosition() {
    const tbl = document.querySelector(this.LOG_TABLE_QUERY_SELECTOR);
    if (tbl) {
      this.scrollPosition = tbl.scrollTop;
    }
  }

  restorePiResultScrollPosition() {
    setTimeout(() => {
      const tbl = document.querySelector(this.LOG_TABLE_QUERY_SELECTOR);
      if (tbl) {
        tbl?.scrollTo({ top: this.scrollPosition });
      }
    }, 1);
  }
}
