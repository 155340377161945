import { Component, computed, effect, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgComponentOutlet } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@core/auth/auth.service';
import { LoginComponent } from '@core/auth/login/login.component';
import { RouteLayoutService } from '@core/navigation/route.layout.service';
import { AccessDeniedComponent } from '@core/pages/access-denied/access-denied.component';
import { RouteGuardService } from '@core/navigation/route.guard.service';
import { RouteTitleService } from '@core/navigation/route.title.service';
import { AuthStatus } from '@core/auth/auth.model';

@Component({
  selector: 'app-root',
  imports: [
    RouterOutlet,
    NgComponentOutlet,
    LoginComponent,
    AccessDeniedComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  titleService = inject(Title);
  authService = inject(AuthService);
  routeGuardService = inject(RouteGuardService);
  routeLayoutService = inject(RouteLayoutService);
  routeTitleService = inject(RouteTitleService);
  authStatus = computed(() => this.authService.state().status);

  layoutComponent = this.routeLayoutService.layoutFromRouteData;
  routeAllowed = computed(() => {
    const rettighed = this.routeGuardService.rettighedFromRoute();
    if (rettighed) {
      return this.authService.hasRettighed(rettighed);
    }
    return true;
  });

  constructor() {
    effect(() => {
      this.titleService.setTitle(this.routeTitleService.titleFromRoute());
    });
  }

  ngOnInit(): void {
    //PrimeNg locale danish
    // this.primeConfig.setTranslation(locales.da);
  }

  protected readonly AuthStatus = AuthStatus;
}
