@let i = indstillinger();
<p-scroll-panel styleClass="h-full">
  <table class="detail-table">
    <tr>
      <td class="detail-label">Aldersopsparing til livrente</td>
      <td>{{ i.aldersopsparingTilLivrenteOverloebMuligt | boolean }}</td>
    </tr>
    <tr>
      <td class="detail-label">Aldersopsparing til rate</td>
      <td>{{ i.aldersopsparingTilRateOverloebMuligt | boolean }}</td>
    </tr>
    <tr>
      <td class="detail-label">Rate til aldersopsparing:</td>
      <td>{{ i.rateTilAldersopsparingOverloebMuligt | boolean }}</td>
    </tr>
    <tr>
      <td class="detail-label">Rate til livrente:</td>
      <td>{{ i.rateTilLivrenteOverloebMuligt | boolean }}</td>
    </tr>
  </table>
</p-scroll-panel>
