import { Component, computed, input } from '@angular/core';
import { BooleanPipe } from '@core/pipes/boolean.pipe';
import { DatePipe, DecimalPipe, JsonPipe } from '@angular/common';
import { PengeinstitutIndstilling } from '../../pi-indstilling/pengeinstitutindstilling.model';
import { Pengeinstitut } from '../../pengeinstitut.model';
import { ScrollPanel } from 'primeng/scrollpanel';

@Component({
  selector: 'app-pi-detail-indstillinger',
  imports: [
    BooleanPipe,
    DatePipe,
    DecimalPipe,
    JsonPipe,
    ScrollPanel
  ],
  templateUrl: './pi-detail-indstillinger.component.html',
  styleUrl: './pi-detail-indstillinger.component.css'
})
export class PiDetailIndstillingerComponent {
  indstillinger = input.required<PengeinstitutIndstilling>();
  pi = input.required<Pengeinstitut>();


  getAnbefaling(type: string) {
    const data = this.indstillinger().anbefaletDaekningsVaerdier;
    return data?.filter(x => x.type === type);
  }

  anbefalingHelbred = computed(() => {
    const data = this.getAnbefaling('LetsikringAfHelbred');
    if (data && data.length > 0) {
      return data[0].anbefaletTilvalgt || false;
    }
    return false;
  });

  anbefalingBarnVedDoed = computed(() => {
    const data = this.getAnbefaling('LetsikringAfBarn');
    if (data && data.length > 0) {
      return data[0].anbefaletTilvalgt || false;
    }
    return false;
  });


  aldersgrupper = computed(() => {
    const data = this.indstillinger().anbefaletDaekningsVaerdier;
    const aldersGruppeIds: number[] = [];
    const aldersGrupper: AnbefalingGruppe[] = [];
    data?.forEach(x => {
      if (x.aarsIntervalId && !aldersGruppeIds.includes(x.aarsIntervalId)) {
        aldersGruppeIds.push(x.aarsIntervalId);
        if (x.aarsIntervalStart != null && x.aarsIntervalSlut != null) {
          aldersGrupper.push({ id: x.aarsIntervalId, start: x.aarsIntervalStart, slut: x.aarsIntervalSlut });
        }
      }
    });
    return aldersGrupper.sort((a, b) => a.start - b.start);
  });

  beloebsgrupper = computed(() => {
    const data = this.indstillinger().anbefaletDaekningsVaerdier;
    const beloebsGruppeIds: number[] = [];
    const beloebsGrupper: AnbefalingGruppe[] = [];
    data?.forEach(x => {
      if (x.beloebIntervalId && !beloebsGruppeIds.includes(x.beloebIntervalId)) {
        beloebsGruppeIds.push(x.beloebIntervalId);
        if (x.beloebIntervalStart != null && x.beloebIntervalSlut != null) {
          beloebsGrupper.push({ id: x.beloebIntervalId, start: x.beloebIntervalStart, slut: x.beloebIntervalSlut });
        }
      }
    });
    return beloebsGrupper.sort((a, b) => a.start - b.start);
  });

  getAnbefalingDoed(aarsIntervalId: number, civilStand: string, positivFormue: boolean) {
    const anbefalinger = this.getAnbefaling('LetsikringVedDoed');
    if (anbefalinger) {
      const found = anbefalinger.filter(x =>
        x.aarsIntervalId == aarsIntervalId
        && x.civilStand == civilStand
        && x.positivFormue == positivFormue);
      if (found.length > 0) {
        return found[0].antalAar;
      }
      return undefined;
    }
    return undefined;
  }

  getAnbefalingSygdom(aarsIntervalId: number, beloebsIntervalId: number, positivFormue: boolean) {
    const anbefalinger = this.getAnbefaling('LetsikringAfSygdom');
    if (anbefalinger) {
      const found = anbefalinger.filter(x =>
        x.aarsIntervalId == aarsIntervalId
        && x.beloebIntervalId == beloebsIntervalId
        && x.positivFormue == positivFormue);
      if (found.length > 0) {
        if (found[0].procent) {
          return (found[0].procent) * 100;
        }
        return null;
      }
      return null;
    }
    return null;
  }

  getAnbefalingKritiskSygdom(aarsIntervalId: number, positivFormue: boolean) {
    const anbefalinger = this.getAnbefaling('LetsikringVedKritiskSygdom');
    if (anbefalinger) {
      const found = anbefalinger.filter(x =>
        x.aarsIntervalId == aarsIntervalId
        && x.positivFormue == positivFormue);
      if (found.length > 0) {
        return found[0].daekningssum;
      }
      return null;
    }
    return null;
  }
}

interface AnbefalingGruppe {
  id: number;
  start: number;
  slut: number;
}
