import { Component, inject } from '@angular/core';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuModule } from 'primeng/menu';
import { NavigationService } from '@core/navigation/navigation.service';

@Component({
    selector: 'app-side-menu',
    imports: [
        PanelMenuModule,
        MenuModule
    ],
    templateUrl: './side-menu.component.html',
    styleUrl: './side-menu.component.css'
})
export class SideMenuComponent {
  navigationService = inject(NavigationService);
  menuItems = this.navigationService.getMenuItems();

}
