import { Component, effect, inject, input, signal, untracked } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';
import { BrugerHistorikService } from './bruger-historik.service';
import { BrugerHistorik } from './bruger-historik.model';

@Component({
  selector: 'app-bruger-historik',
  imports: [
    DatePipe,
    SharedModule,
    TableModule,
    TableRowDirective
  ],
  templateUrl: './bruger-historik.component.html',
  styleUrl: './bruger-historik.component.css'
})
export class BrugerHistorikComponent {
  brugerId = input.required<number>();
  historik = signal<BrugerHistorik[]>([]);

  brugerHistorikService = inject(BrugerHistorikService);

  constructor() {
    effect(async () => {
      if (this.brugerId() > 0) {
        await untracked(async () => {
          this.brugerHistorikService.load({
            params: {
              sortBy: 'tidspunkt',
              descending: true,
              filter: JSON.stringify({
                brugerId: this.brugerId()
              })

            }
          });
        });
      }
    });
  }


}
