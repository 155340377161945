import { inject, Injectable, signal } from '@angular/core';
import { SamfundsForudsaetninger } from './samfundsforudsaetninger.model';
import { appConfig } from '../app-config';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SatserService {
  private httpClient = inject(HttpClient);
  samfundsforudsaetninger = signal<SamfundsForudsaetninger | undefined>(undefined);

  constructor() {
    this.loadSamfundsForudsetninger();
  }

  async loadSamfundsForudsetninger() {
    try {
      let url = `${appConfig.apiRoot}/api/satser/samfund`;
      const source = this.httpClient.get<SamfundsForudsaetninger>(url);
      const response = await firstValueFrom(source);
      this.samfundsforudsaetninger.set(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
    }
  }

}
