import { Component, computed, inject, signal } from '@angular/core';
import { BrugerService } from '../bruger.service';
import { DatePipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { RouterLink } from '@angular/router';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { InputText } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { IconField } from 'primeng/iconfield';
import { InputIcon } from 'primeng/inputicon';
import { Select } from 'primeng/select';
import { RollerService } from '../../roller/roller.service';
import { OrganisationService } from '../../organisation/organisation.service';

@Component({
  selector: 'app-brugere-grid',
  imports: [
    TableModule,
    DatePipe,
    RouterLink,
    TableRowDirective,
    ButtonModule,
    ToolbarModule,
    InputText,
    FormsModule,
    IconField,
    InputIcon,
    Select
  ],
  templateUrl: './brugere-grid.component.html',
  styleUrl: './brugere-grid.component.css'
})
export class BrugereGridComponent {
  brugereService = inject(BrugerService);
  rollerService = inject(RollerService);
  organisationService = inject(OrganisationService);
  brugere = computed(() => this.brugereService.data());
  searchText = signal<string>('');
  searchRolleId = signal<number | undefined>(undefined);
  searchOrganisationId = signal<number | undefined>(undefined);

  async onSearch() {
    await this.brugereService.load({
      params: {
        filter: JSON.stringify({
          text: this.searchText(),
          rolleId: this.searchRolleId(),
          organisationId: this.searchOrganisationId()
        })
      }
    });
  }
}
