@let i = indstillinger();
<p-scroll-panel styleClass="h-full">
  <table class="detail-table">
    <tr>
      <td class="detail-label">Antal decimaler investeringsvalgandele</td>
      <td>{{ i.antalDecimalerInvesteringsvalgAndele }}</td>
    </tr>
    <tr>
      <td class="detail-label">Link til investeringsunivers</td>
      <td>{{ pi().investeringsUniversLink }}</td>
    </tr>
  </table>
  <br />
  <table class="detail-table">
    <tr>
      <td class="detail-label">Omkostningssatser</td>
      <td></td>
    </tr>
  </table>
  <table class="detail-table bordered">
    <tr>
      <th>Aktivklasse</th>
      <th>Omkostning</th>
    </tr>
    <tr>
      <td>Kontant</td>
      <td class="numbers text-right">
        {{ satserService.samfundsforudsaetninger()?.kontant | percent }}
      </td>
    </tr>
    <tr>
      <td>Stats- og realkreditobligationer</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.statOgRealkredit
            | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>Investment grade obligationer</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.investmentGrade
            | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>High-yield obligationer</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.highYield | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>Emerging markets statsobligationer</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.emergingMarketsObligationer
            | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>Globale aktier (developed markets)</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.globaleAktier
            | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>Emerging markets aktier</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.emergingMarketsAktier
            | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>Private equity</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.privateEquity
            | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>Infrastruktur</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.infrastruktur
            | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>Ejendomme</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.ejendomme | percent : "1.2-4"
        }}
      </td>
    </tr>
    <tr>
      <td>Hedgefonde</td>
      <td class="numbers text-right">
        {{
          satserService.samfundsforudsaetninger()?.hedgeFonde
            | percent : "1.2-4"
        }}
      </td>
    </tr>
  </table>
</p-scroll-panel>
