import { Component, computed, input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { RolleDetailComponent } from '../rolle-detail/rolle-detail.component';
import { RollerGridComponent } from '../roller-grid/roller-grid.component';

@Component({
  selector: 'app-roller',
  imports: [TableModule, RollerGridComponent, RolleDetailComponent],
  templateUrl: './roller-main.component.html',
  styleUrl: './roller-main.component.css',
})
export class RollerMainComponent {
  id = input<string>();
  rolleId = computed(() => Number(this.id()));
}
