import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Button } from 'primeng/button';
import { InputText } from 'primeng/inputtext';
import { Message } from 'primeng/message';
import { RaadgivereService } from '../raadgivere.service';
import { RaadgiverSearchParams } from './raadgiver-search-params.model';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-raadgiver-search',
  imports: [Button, ReactiveFormsModule, InputText, Message, CheckboxModule],
  templateUrl: './raadgiver-search.component.html',
  styleUrl: './raadgiver-search.component.css'
})
export class RaadgiverSearchComponent {
  raadgiverService = inject(RaadgivereService);
  validationMessage = signal('');

  form = new FormGroup({
    navn: new FormControl(),
    regnr: new FormControl(),
    brugerId: new FormControl(),
    formue: new FormControl(false),
    lts: new FormControl(false),
    fal: new FormControl(false)
  });

  async onClickSearch() {
    this.validationMessage.set('');
    this.raadgiverService.raadgiverSearchParams = {
      ...(this.form.value as RaadgiverSearchParams)
    };

    await this.raadgiverService.searchRaadgiver();
    if (this.raadgiverService.data().length == 0) {
      this.validationMessage.set('Ingen hits');
    }
  }

  onClickReset() {
    this.form.setValue({
      navn: null,
      regnr: null,
      brugerId: null,
      formue: false,
      lts: false,
      fal: false
    });
  }
}
