import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  private _rettighedFromRoute = signal(undefined);
  rettighedFromRoute = this._rettighedFromRoute.asReadonly();

  constructor() {
    const subscription = this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        const rettighedDefinedInRoute = data.state.root.firstChild?.data['rettighed'];
        this._rettighedFromRoute.set(rettighedDefinedInRoute);
      }
    });
    this.destroyRef.onDestroy(() => subscription.unsubscribe());
  }

}

