import { AccountInfo } from '@azure/msal-browser';

export enum AuthStatus {
  UNAUTHENTICATED = 'Logget ud',
  PENDING = 'Logger ind...',
  FAILED = 'Login fejlede',
  AUTHENTICATED = 'Et øjeblik, mens vi tjekker din adgang',
  AUTHORIZED = 'Logget ind',
  USERCHECK = 'Kontrollerer bruger',
  USERCHECK_OK = 'Brugerkontrol OK',
  USERCHECK_UNREGISTERED = 'Bruger ikke registreret',
  USERCHECK_FAILED = 'Fejl ved kontrol af brugernavn',
  USERCHECK_NETWORK_FAILED = 'Ingen adgang til API. Check evt. netværksforbindelsen.'
};

export interface AuthState {
  status: AuthStatus;
  failed: boolean;
  account?: AccountInfo;
  bruger: AuthBruger;
  accessToken: string;
  username: string;
}

export interface AuthBruger {
  id: number;
  fornavn?: string;
  efternavn?: string;
  rettigheder: string[];
  email: string;
  organisationId: number;
  organisation: {
    type: string;
  };
}
