import { DatePipe } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { LogDetailSsbService } from './log-detail-ssb.service';
import { LogSsbContentComponent } from './log-ssb-content/log-ssb-content.component';

@Component({
  selector: 'app-log-detail-ssb',
  imports: [DatePipe, LogSsbContentComponent],
  templateUrl: './log-detail-ssb.component.html',
  styleUrl: './log-detail-ssb.component.css',
})
export class LogDetailSsbComponent {
  logDetailSsbService = inject(LogDetailSsbService);
  data = computed(() => {
    if (this.logDetailSsbService.data().length > 0) {
      return this.logDetailSsbService.data()[0];
    } else {
      return null;
    }
  });
}
