<div class="h-full overflow-hidden">
  <p-scroll-panel styleClass="h-full">
    <div class="detail-section-header">Detaljer</div>
    @let logEntry = logService.selectedLogEntry(); @if(logEntry){
    <table class="detail-table">
      <tr>
        <td class="detail-label">Id</td>
        <td>{{ logEntry.id }}</td>
      </tr>
      <tr>
        <td class="detail-label">Metode</td>
        <td>{{ logEntry.metodeNavn }}</td>
      </tr>
      <tr>
        <td class="detail-label">Starttidspunkt</td>
        <td>{{ logEntry.startTid | date : "dd-MM-YYYY HH:mm:ss" }}</td>
      </tr>
      <tr>
        <td class="detail-label">Sluttidspunkt</td>
        <td>{{ logEntry.slutTid | date : "dd-MM-YYYY HH:mm:ss" }}</td>
      </tr>
      <tr>
        <td class="detail-label">Maskine</td>
        <td>{{ logEntry.maskine }}</td>
      </tr>
      <tr>
        <td class="detail-label">Regnr</td>
        <td>{{ logEntry.regnr }}</td>
      </tr>
      <tr>
        <td class="detail-label">Brugernavn</td>
        <td>{{ logEntry.brugernavn }}</td>
      </tr>
      <tr>
        <td class="detail-label">Rådgiver</td>
        <td>{{ logEntry.raadgiverId }}</td>
      </tr>
      <tr>
        <td class="detail-label">Kunde id</td>
        <td>{{ logEntry.kundeId }} - {{ logEntry.cpr }}</td>
      </tr>
      <tr>
        <td class="detail-label">Tilbud id</td>
        <td>{{ logEntry.tilbudId }} - {{ logEntry.tilbudNavn }}</td>
      </tr>
      <tr>
        <td class="detail-label">Session id</td>
        <td>{{ logEntry.sessionId }}</td>
      </tr>
      <tr>
        <td class="detail-label">Request id</td>
        <td>{{ logEntry.requestId }}</td>
      </tr>
    </table>
    <p-divider />
    <div class="detail-section-header">Kald</div>
    <app-log-detail-calls />
    <p-divider />
    <div class="detail-section-header">Log beskeder</div>
    <app-log-detail-messages />
    }
  </p-scroll-panel>
</div>
