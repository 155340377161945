import { Component, inject } from '@angular/core';
import { PuSearchComponent } from './pu-search/pu-search.component';
import { PuResultComponent } from './pu-result/pu-result.component';
import { PuDetailComponent } from './pu-detail/pu-detail.component';
import { PensionsudbydereService } from './pensionsudbydere.service';
import { TabsModule } from 'primeng/tabs';

@Component({
  selector: 'app-pensionsudbydere',
  imports: [
    TabsModule,
    PuSearchComponent,
    PuResultComponent,
    PuDetailComponent
  ],
  templateUrl: './pensionsudbydere.component.html',
  styleUrl: './pensionsudbydere.component.css'
})
export class PensionsudbydereComponent {
  puService = inject(PensionsudbydereService);

  onSearchResultClick() {
    this.puService.restorePuResultScrollPosition();
  }
}
