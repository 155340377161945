import { Injectable } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { Rolle } from './rolle.model';

@Injectable({
  providedIn: 'root'
})
export class RollerService extends ApiRestService<Rolle> {

  constructor() {
    super('/v2/roller');
    this.load({ params: { sortBy: 'navn' } }).then();
  }

}
