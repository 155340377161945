import { Component, input } from '@angular/core';
import { BooleanPipe } from '@core/pipes/boolean.pipe';
import { DecimalPipe } from '@angular/common';
import { PengeinstitutIndstilling } from '../../pi-indstilling/pengeinstitutindstilling.model';
import { ScrollPanel } from 'primeng/scrollpanel';

@Component({
  selector: 'app-pi-detail-indbetalingsaftale',
  imports: [
    BooleanPipe,
    DecimalPipe,
    ScrollPanel
  ],
  templateUrl: './pi-detail-indbetalingsaftale.component.html',
  styleUrl: './pi-detail-indbetalingsaftale.component.css'
})
export class PiDetailIndbetalingsaftaleComponent {
  indstillinger = input.required<PengeinstitutIndstilling>();

}
