import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { TableLazyLoadEvent, TableModule, TableRowSelectEvent } from 'primeng/table';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';
import { LogEntry } from '../log.model';
import { LogService } from '../log.service';

@Component({
  selector: 'app-log-result',
  imports: [
    DatePipe,
    DecimalPipe,
    SharedModule,
    TableRowDirective,
    TableModule,
  ],
  templateUrl: './log-result.component.html',
  styleUrl: './log-result.component.css',
})
export class LogResultComponent {
  logService = inject(LogService);

  loadLazy(event: TableLazyLoadEvent) {
    console.log(event);
  }

  rowClass(item: LogEntry) {
    if (item.fejlet) {
      return '!text-lpred';
    }
    return null;
  }

  onRowSelect(e: TableRowSelectEvent) {
    this.logService.selectLogResultRow(e.data);
  }
}
