<p-scroll-panel styleClass="h-full">
  <div [formGroup]="formGroup">
    <table class="detail-table">
      <tr>
        <td class="detail-label">Navn</td>
        <td>{{ pi().navn }}</td>
      </tr>
      <tr>
        <td class="detail-label">Regnr</td>
        <td class="numbers">{{ pi().regnr }}</td>
      </tr>
      <tr>
        <td class="detail-label">CVR nr</td>
        <td class="numbers">{{ pi().cvrNummer }}</td>
      </tr>
      <tr>
        <td class="detail-label">IT Central</td>
        <td>{{ pi().itCentral }}</td>
      </tr>
      <tr>
        <td class="detail-label">PFA Opsamlingskonto</td>
        <td>{{ pi().pfaOpsamlingsKonto?.regNr }} {{ pi().pfaOpsamlingsKonto?.kontoNummer }}
          ({{ pi().pfaOpsamlingsKonto?.navn }}
          )
        </td>
      </tr>
      <tr>
        <td class="detail-label">PFA Indskudskonto</td>
        <td>{{ pi().pfaIndskudsKonto?.regNr }} {{ pi().pfaIndskudsKonto?.kontoNummer }}
          ({{ pi().pfaIndskudsKonto?.navn }}
          )
        </td>
      </tr>
      <tr>
        <td class="detail-label">PI Opsamlingskonto</td>
        <td>{{ pi().piOpsamlingsKonto?.regNr }} {{ pi().piOpsamlingsKonto?.kontoNummer }}
          ({{ pi().piOpsamlingsKonto?.navn }}
          )
        </td>
      </tr>
      <tr>
        <td class="detail-label">Gældende fra</td>
        <td>{{ pi().gaeldendeFra | date : "dd-MM-YYYY" }}</td>
      </tr>
      <tr>
        <td class="detail-label">Gældende til</td>
        <td>{{ pi().gaeldendeTil | date : "dd-MM-YYYY" }}</td>
      </tr>
      <tr>
        <td class="detail-label">Pengeinstitut indstillinger hentes fra ITC</td>
        <td>{{ pi().indstillingerHentesFraItc | boolean }}</td>
      </tr>
      <tr>
        <td class="detail-label">Send bestandsrapportering til ITC</td>
        <td>{{ pi().sendBestandrapportering | boolean }}</td>
      </tr>
      <tr>
        <td class="detail-label">Vises i liste til PI skifte</td>
        <td>
          @if (allowEdit()) {
            <p-checkbox formControlName="inkluderIpiskifteListe" [binary]="true" />
          } @else {
            {{ pi().inkluderIpiskifteListe|boolean }}
          }
        </td>
      </tr>
    </table>
    <br />
    <div class="detail-section-header">Kontaktinfo</div>
    <table class="detail-table">
      <tr>
        <td class="detail-label">Adresse</td>
        <td>{{ pi().adresse?.vejnavn }} {{ pi().adresse?.vejnr }}</td>
      </tr>
      <tr>
        <td class="detail-label">Postnr og by</td>
        <td>{{ pi().adresse?.postnr }} {{ pi().adresse?.bynavn }}</td>
      </tr>
      <tr>
        <td class="detail-label">E-mail adresse</td>
        <td>{{ pi().email }}</td>
      </tr>
      <tr>
        <td class="detail-label">Telefon</td>
        <td>{{ pi().telefon }}</td>
      </tr>
    </table>
  </div>
</p-scroll-panel>
