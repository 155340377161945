<p-scroll-panel styleClass="h-full">
  <div [formGroup]="form">
    <div class="flex gap-8">
      <div class="flex-1" style="max-width: 400px;">
        <div class="detail-section-header">Stamdata</div>
        <div class="control">
          <label>Navn</label>
          @if (allowEdit()) {
            <input pInputText formControlName="udbyderNavn" />
            @if (form.controls['udbyderNavn'].errors && form.controls['udbyderNavn'].touched) {
              <p class="error">Feltet skal udfyldes</p>
            }
          } @else {
            <div> {{ pu().udbyderNavn }}</div>
          }
        </div>
        <div class="control">
          <label>Regnr</label>
          <div class="numbers">{{ pu().pengeinstitut?.regnr }}</div>
        </div>
        <div class="control">
          <label>NavnNr</label>
          <div>{{ pu().pensionsInfoUdbyderNavnNr }}</div>
        </div>
        <div class="control">
          <label>§41 tilladt fra leverandør</label>
          @if (allowEdit()) {
            <div>
              <p-checkbox [binary]="true" formControlName="p41TilladtFraLeverandoer" />
            </div>
          } @else {
            <div> {{ pu().p41TilladtFraLeverandoer | boolean }}</div>
          }
        </div>
        <div class="control">
          <label>Vises i leverandørlisten</label>
          @if (allowEdit()) {
            <div>
              <p-checkbox formControlName="inkluderIleverandoerliste" [binary]="true" />
            </div>
          } @else {
            {{ pu().inkluderIleverandoerliste|boolean }}
          }
        </div>
      </div>
      <div class="flex-1" style="max-width: 400px;">
        <div class="detail-section-header">Kontaktinfo</div>
        <div formGroupName="adresse">
          <div class="control">
            <label>C/O</label>
            @if (allowEdit()) {
              <input pInputText formControlName="conavn" />
            } @else {
              <div> {{ pu().adresse?.conavn }}</div>
            }
          </div>
          <div class="control">
            <label>Vejnavn</label>
            @if (allowEdit()) {
              <input pInputText formControlName="vejnavn" />
            } @else {
              <div> {{ pu().adresse?.vejnavn }}</div>
            }
          </div>
          <div class="control">
            <label>Vejnummer</label>
            @if (allowEdit()) {
              <input pInputText formControlName="vejnr" />
            } @else {
              <div> {{ pu().adresse?.vejnr }}</div>
            }
          </div>
          <div class="control">
            <label>Sted</label>
            @if (allowEdit()) {
              <input pInputText formControlName="sted" />
            } @else {
              <div> {{ pu().adresse?.sted }}</div>
            }
          </div>
          <div class="control">
            <label>Postnummer</label>
            @if (allowEdit()) {
              <input pInputText formControlName="postnr" />
            } @else {
              <div> {{ pu().adresse?.postnr }}</div>
            }
          </div>
          <div class="control">
            <label>Bynavn</label>
            @if (allowEdit()) {
              <input pInputText formControlName="bynavn" />
            } @else {
              <div> {{ pu().adresse?.bynavn }}</div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  @if (allowEdit()) {
    <p-button label="Gem ændringer" icon="pi pi-save" (click)="onClickSave()" />
  }

</p-scroll-panel>
<p-dialog header="Gem ændringer" [modal]="true" [(visible)]="showSaveDialog" [style]="{ width: '25rem' }">
  <div class="control">
    <label for="username">Kommentar</label>
    <textarea [(ngModel)]="kommentar" pTextarea id="username" autocomplete="off"></textarea>
  </div>
  <div class="flex justify-end gap-2">
    <p-button label="Gem" (click)="saveChanges()" [disabled]="!saveDialogIsValid()" icon="pi pi-save"
              [loading]="saving()" />
  </div>
</p-dialog>

