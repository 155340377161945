<div class="flex flex-col h-full">
  <div>
    <h1>Raadgivere</h1>
  </div>
  <p-tabs
    [(value)]="raadgiverService.activeTab"
    class="flex-1 border-1 overflow-hidden"
  >
    <p-tablist>
      <p-tab value="search"><i class="pi pi-search"></i> Søgning</p-tab>
      @if (raadgiverService.data().length > 0) {
        <p-tab value="result" (click)="onSearchResultClick()"
        ><i class="pi pi-list"></i> Resultat
        </p-tab
        >
      }
      @if (raadgiverService.selectedRaadgiver() || raadgiverService.mode() === 'create') {
        <p-tab value="detail"><i class="pi pi-file"></i> Detaljer</p-tab>
      }
      <div class="mt-2 ml-8">
        <p-button icon="pi pi-plus" outlined label="Opret rådgiver" (click)="onClickOpret()"></p-button>
      </div>
    </p-tablist>
    <p-tabpanels class="overflow-hidden">
      <p-tabpanel value="search">
        <app-raadgiver-search />
      </p-tabpanel>
      <p-tabpanel value="result">
        <app-raadgiver-result />
      </p-tabpanel>
      <p-tabpanel value="detail">
        <app-raadgiver-detail />
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>
</div>
