import { Component, inject } from '@angular/core';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'primeng/tabs';
import { LogDetailComponent } from './log-detail/log-detail.component';
import { LogResultComponent } from './log-result/log-result.component';
import { LogSearchComponent } from './log-search/log-search.component';
import { LogService } from './log.service';
import { LogDetailSsbComponent } from './log-detail-ssb/log-detail-ssb.component';

@Component({
  selector: 'app-log',
  imports: [
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    LogSearchComponent,
    LogResultComponent,
    LogDetailComponent,
    LogDetailSsbComponent,
  ],
  templateUrl: './log.component.html',
  styleUrl: './log.component.css',
})
export class LogComponent {
  logService = inject(LogService);

  onSearchResultClick() {
    this.logService.restoreLogresultScrollPosition();
  }
}
