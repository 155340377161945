import { Component, computed, effect, inject, input, untracked } from '@angular/core';
import { BrugerRollerService } from './bruger-roller.service';
import { RollerService } from '../../roller/roller.service';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { Rolle } from '../../roller/rolle.model';
import { BrugerRolle } from './bruger-rolle.model';
import { BrugerDetailService } from '../bruger-detail/bruger-detail.service';
import { TooltipModule } from 'primeng/tooltip';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { appConfig } from '@core/app-config';
import { firstValueFrom } from 'rxjs';
import { ScrollPanel } from 'primeng/scrollpanel';

@Component({
  selector: 'app-bruger-roller',
  imports: [CheckboxModule, FormsModule, TooltipModule, DatePipe, ScrollPanel],
  templateUrl: './bruger-roller.component.html',
  styleUrl: './bruger-roller.component.css',
})
export class BrugerRollerComponent {
  httpClient = inject(HttpClient);
  brugerId = input.required<number>();
  isLetpension = input.required<boolean>();
  brugerDetailService = inject(BrugerDetailService);
  brugerRolleService = inject(BrugerRollerService);
  rolleService = inject(RollerService);

  constructor() {
    effect(async () => {
      const roller = this.rolleService.data();
      if (roller.length === 0) return;

      const brugerId = this.brugerId();

      await untracked(async () => {
        if (brugerId > 0) {
          await this.brugerRolleService.load({
            params: {
              filter: JSON.stringify({
                brugerId: brugerId,
              }),
            },
          });
        }
      });
    });
  }

  rolleData = computed(() => {
    const _rolleData: RolleData[] = [];
    this.rolleService.data().forEach((rolle) => {
      const brCheck = this.brugerRolleService
        .data()
        .filter((brugerRolle) => brugerRolle.rolleId === rolle.id);
      if (brCheck.length > 0) {
        const brugerRolle = brCheck[0];
        _rolleData.push({
          ...rolle,
          brugerRolle: brugerRolle,
          checked: !!brugerRolle,
          sendVelkomstMail: false,
        });
      } else {
        _rolleData.push({ ...rolle, checked: false, sendVelkomstMail: false });
      }
    });
    return _rolleData;
  });

  grant(r: RolleData) {
    if (r.id) {
      if (r.checked) {
        if (this.brugerDetailService.changes()[r.id]?.action === 'add') {
          delete this.brugerDetailService.changes()[r.id];
        } else {
          this.brugerDetailService.changes()[r.id] = {
            action: 'remove',
            id: r.brugerRolle?.id,
          };
        }
      } else {
        if (this.brugerDetailService.changes()[r.id]?.action === 'remove') {
          delete this.brugerDetailService.changes()[r.id];
        } else {
          this.brugerDetailService.changes()[r.id] = {
            action: 'add',
            rolleId: r.id,
          };
        }
      }
    }
  }

  async sendMail(brugerRolle: BrugerRolle) {
    try {
      brugerRolle.loading = true;
      const url = `${appConfig.apiRoot}/api/BrugerRoller/sendmail/${brugerRolle.id}`;
      console.log(url);
      const source = this.httpClient.post(url, {});
      await firstValueFrom(source);
      brugerRolle.velkomstmailAfsendt = new Date();
      brugerRolle.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  bestilVelkomstMail(r: RolleData) {
    const ch = this.brugerDetailService.changes();
    ch[r.id].sendVelkomstmail = !r.sendVelkomstMail;
    this.brugerDetailService.changes.set(ch);
  }
}

interface RolleData extends Rolle {
  checked: boolean;
  sendVelkomstMail: boolean;
  brugerRolle?: BrugerRolle;
}
