@let i = indstillinger();
<p-scroll-panel styleClass="h-full">
  <table class="detail-table">
    <tr>
      <td class="detail-label">Privat</td>
      <td></td>
    </tr>
    <tr>
      <td>Debiteringskonto krævet</td>
      <td>{{ i.privateIndbetalingDebiteringskotoKraevet }}</td>
    <tr>
      <td>Indbetaling mulig for op til maksimum</td>
      <td>{{ i.privatIndbetalingIndbetalingMuligForOpTilMaksimum | boolean }}</td>
    </tr>
    <tr>
      <td>Kombiindbetaling mulig</td>
      <td>{{ i.privatIndbetalingKombiIndbetalingMulig | boolean }}</td>
    </tr>
    <tr>
      <td>Regulering til kapitalloft anvendelse</td>
      <td>{{ i.privatIndbetalingReguleringTilKapLoftAnvendelse | boolean }}</td>
    </tr>
    <tr>
      <td>Regulering til rateloft anvendelse</td>
      <td>{{ i.privatIndbetalingReguleringTilRateLoftAnvendelse | boolean }}</td>
    </tr>
    <tr>
      <td class="detail-label">Arbejdsgiver</td>
      <td></td>
    </tr>
    <tr>
      <td>Antal decimaler</td>
      <td>{{ i.arbejdsgiverIndbetalingAntalDecimaler }}</td>
    <tr>
    <tr>
      <td>Kombiindbetaling mulig</td>
      <td>{{ i.arbejdsgiverIndbetalingKombiIndbetalingMulig | boolean }}</td>
    </tr>
    <tr>
      <td>Default livrente rabat under grænse</td>
      <td>{{ i.livrenteDefaultIndbetalingRabatUnderGraense | number:'1.2-2' }} %</td>
    </tr>
    <tr>
      <td>Default livrente rabat over grænse</td>
      <td>{{ i.livrenteDefaultIndbetalingRabatOverGraense | number:'1.2-2' }} %</td>
    </tr>
    <tr>
      <td>Default rabat grænse</td>
      <td>{{ i.livrenteDefaultIndbetalingRabatGraense | number:'1.0' }}</td>
    </tr>
  </table>
</p-scroll-panel>
