import { Component, effect, inject, input, signal, untracked } from '@angular/core';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { Bruger } from '../bruger.model';
import { BrugerService } from '../bruger.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Message } from 'primeng/message';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'primeng/tabs';
import { BrugerHistorikComponent } from '../bruger-historik/bruger-historik.component';
import { BrugerNotifikationerComponent } from '../bruger-notifikationer/bruger-notifikationer.component';
import { PanelModule } from 'primeng/panel';
import { BrugerRollerComponent } from '../bruger-roller/bruger-roller.component';
import { BrugerDetailService } from './bruger-detail.service';

@Component({
  selector: 'app-bruger-detail',
  imports: [
    ToolbarModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    SplitButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    Message,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    BrugerHistorikComponent,
    BrugerNotifikationerComponent,
    PanelModule,
    BrugerRollerComponent,
    RouterLink
  ],
  templateUrl: './bruger-detail.component.html',
  styleUrl: './bruger-detail.component.css'
})
export class BrugerDetailComponent {
  id = input.required<number>();

  brugerDetailService = inject(BrugerDetailService);
  brugerService = inject(BrugerService);
  router = inject(Router);

  bruger = signal<Bruger | {}>({});
  validationMessage = signal<string | undefined>(undefined);

  constructor() {
    effect(async () => {
      if (this.id() > 0) {
        await untracked(async () => {
          const response = await this.brugerService.getById(this.id());
          this.bruger.set(response.data);
          this.form.patchValue(response.data);
        });
      } else {
        this.bruger.set({});
      }
    });
  }

  form = new FormGroup({
    fornavn: new FormControl('', {
      validators: [Validators.required]
    }),
    efternavn: new FormControl('', {
      validators: [Validators.required]
    }),
    email: new FormControl('', {
      validators: [Validators.required, Validators.email]
    })
  });

  get emailIsInvalid() {
    return (
      this.form.controls.email.touched &&
      this.form.controls.email.dirty &&
      this.form.controls.email.invalid
    );
  }

  get fornavnIsInvalid() {
    return (
      this.form.controls.fornavn.touched &&
      this.form.controls.fornavn.dirty &&
      this.form.controls.fornavn.invalid
    );
  }

  get efternavnIsInvalid() {
    return (
      this.form.controls.efternavn.touched &&
      this.form.controls.efternavn.dirty &&
      this.form.controls.efternavn.invalid
    );
  }

  get isLetpension() {
    return !!this.form.controls.email.value?.endsWith('@letpension.dk');
  }

  async onClickSave() {
    this.validationMessage.set(undefined);
    if (this.form.invalid) {
      return;
    }
    const newData = {
      ...this.bruger(),
      ...this.form.value
    } as Bruger;

    let response = await this.brugerDetailService.saveBruger(this.id(), newData);
    await this.brugerDetailService.saveBrugerRoller(response.data.id);
    if (response.success) {
      await this.brugerService.reload();
      await this.router.navigate(['/brugeradm/brugere']);
    } else {
      this.validationMessage.set(response.message);
    }

  }
}
