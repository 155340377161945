<div class="h-full overflow-hidden">
  <p-scroll-panel styleClass="h-full">
    <form [formGroup]="form">
      <div class="control" style="width: 340px">
        <label>Periode</label>
        <p-select
          [options]="periodeOptions"
          formControlName="periode"
          placeholder="Vælg periode"
          optionLabel="text"
          optionValue="value"
          (onChange)="onPeriodeChange($event)"
        />
      </div>

      <div class="flex gap-4" style="width: 340px">
        <div class="control">
          <label>Starttidspunkt</label>
          <p-datePicker
            formControlName="startTidspunktDate"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            [showTime]="true"
            [hourFormat]="'24'"
            (onSelect)="resetPeriode()"
          />
        </div>
        <div class="control">
          <label>Sluttidspunkt</label>
          <p-datePicker
            formControlName="slutTidspunktDate"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            [showTime]="true"
            [hourFormat]="'24'"
            (onSelect)="resetPeriode()"
          />
        </div>
      </div>
      <div class="control">
        <label>Fejlet</label>
        <p-checkbox formControlName="fejlet" [binary]="true" />
      </div>
      <div class="flex gap-6">
        <div class="flex-1" style="max-width: 420px">
          <div class="control">
            <label>Cpr nr.</label>
            <input pInputText formControlName="cpr" />
          </div>
          <div class="control">
            <label>Rådgiver id</label>
            <input pInputText formControlName="raadgiverId" />
          </div>
          <div class="control">
            <label>Letpension brugernavn</label>
            <input pInputText formControlName="brugernavn" />
          </div>
          <div class="control">
            <label>Tilbuds id</label>
            <input pInputText formControlName="tilbudId" />
          </div>
          <div class="control">
            <label>Forslags- /Tilbudsnavn</label>
            <input pInputText formControlName="tilbudNavn" />
          </div>
        </div>
        <div class="flex-1" style="max-width: 420px">
          <div class="control">
            <label>Request id</label>
            <input pInputText formControlName="requestId" />
          </div>
          <div class="control">
            <label>Session id</label>
            <input pInputText formControlName="sessionId" />
          </div>
          <div class="control">
            <label>IT Central</label>
            <p-select
              [options]="itcOptions"
              formControlName="itCentral"
              placeholder="Vælg ITC"
              [showClear]="true"
            />
          </div>
          <div class="control">
            <label>Regnr</label>
            <input pInputText formControlName="regnr" />
          </div>
        </div>
        <div class="flex-1">
          <div class="control">
            <label>Maskine</label>
            <p-select
              [options]="maskinerService.data()"
              formControlName="maskine"
              placeholder="Vælg maskine"
              [showClear]="true"
            />
          </div>
          <div class="control">
            <label>System</label>
            <p-select
              [options]="systemerService.data()"
              formControlName="system"
              placeholder="Vælg system"
              [showClear]="true"
            />
          </div>
          <div class="control">
            <label>Metode</label>
            <p-select
              [options]="metoderService.getDropdownData()"
              formControlName="metode"
              placeholder="Vælg metode"
              [showClear]="true"
            />
          </div>
          <div class="control">
            <label>Tidsforbrug ></label>
            <p-inputgroup class="w-full md:!w-[30rem]">
              <input pInputText formControlName="tidsforbrug" />
              <p-inputgroup-addon>ms</p-inputgroup-addon>
            </p-inputgroup>
          </div>
        </div>
      </div>
      <div class="flex gap-2">
        <p-button
          label="Søg"
          (click)="onClickSearch()"
          [loading]="logService.isLoading()"
          type="submit"
        />
        <p-button label="Nulstil" (click)="onClickReset()" />
      </div>
      @if (validationMessage()) {
        <p-message [text]="validationMessage()" severity="error" styleClass="mt-4" />
      }
    </form>
  </p-scroll-panel>
</div>
