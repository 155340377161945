import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import dayjs from 'dayjs';
import { Button } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DatePicker } from 'primeng/datepicker';
import { InputText } from 'primeng/inputtext';
import { Message } from 'primeng/message';
import { LogService } from '../log.service';
import { LogSearchParams } from './log-search-params.model';
import { SelectChangeEvent, SelectModule } from 'primeng/select';
import { LogSearchMaskinerService } from './log-search-maskiner.service';
import { LogSearchSystemerService } from './log-search-systemer.service';
import { InputGroup } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { LogSearchMetoderService } from './log-search-metoder.service';
import { ScrollPanel } from 'primeng/scrollpanel';

@Component({
  selector: 'app-log-search',
  imports: [
    CheckboxModule,
    ReactiveFormsModule,
    SelectModule,
    InputGroup,
    InputGroupAddonModule,
    InputText,
    Message,
    DatePicker,
    Button,
    ScrollPanel
  ],
  templateUrl: './log-search.component.html',
  styleUrl: './log-search.component.css'
})
export class LogSearchComponent {
  logService = inject(LogService);
  maskinerService = inject(LogSearchMaskinerService);
  systemerService = inject(LogSearchSystemerService);
  metoderService = inject(LogSearchMetoderService);
  validationMessage = signal('');

  itcOptions = ['BD', 'BEC', 'SDC'];

  periodeOptions = [
    { text: 'Sidste 20 minutter', value: 'sidste20minutter' },
    { text: 'Sidste time', value: 'sidsteTime' },
    { text: 'I dag', value: 'idag' },
    { text: 'I går', value: 'igaar' },
    { text: 'Sidste 7 dage', value: 'sidste7dage' },
    { text: 'Sidste 30 dage', value: 'sidste30dage' }
  ];

  form = new FormGroup({
    startTidspunktDate: new FormControl(
      dayjs().subtract(20, 'minute').toDate()
    ),
    slutTidspunktDate: new FormControl(dayjs().add(5, 'minute').toDate()),

    periode: new FormControl(),
    fejlet: new FormControl(),
    requestId: new FormControl(),
    sessionId: new FormControl(),
    regnr: new FormControl(),
    itCentral: new FormControl(),
    cpr: new FormControl(),
    raadgiverId: new FormControl(),
    brugernavn: new FormControl(),
    tilbudId: new FormControl(),
    tilbudNavn: new FormControl(),

    maskine: new FormControl(),
    system: new FormControl(),
    metode: new FormControl(),
    fritekst: new FormControl(),
    tidsforbrug: new FormControl()
  });

  resetPeriode() {
    this.form.patchValue({ periode: null });
  }

  onPeriodeChange(e: SelectChangeEvent) {
    const p = e.value;

    if (p == 'sidste20minutter') {
      this.form.patchValue({
        startTidspunktDate: dayjs().subtract(20, 'minute').toDate(),
        slutTidspunktDate: dayjs().add(5, 'minute').toDate()
      });
    }
    if (p == 'sidsteTime') {
      this.form.patchValue({
        startTidspunktDate: dayjs().subtract(1, 'hour').toDate(),
        slutTidspunktDate: dayjs().add(5, 'minute').toDate()
      });
    }
    if (p == 'sidste7dage') {
      this.form.patchValue({
        startTidspunktDate: dayjs().subtract(1, 'week').startOf('day').toDate(),
        slutTidspunktDate: dayjs().add(5, 'minute').toDate()
      });
    }
    if (p == 'sidste30dage') {
      this.form.patchValue({
        startTidspunktDate: dayjs().subtract(30, 'day').startOf('day').toDate(),
        slutTidspunktDate: dayjs().add(5, 'minute').toDate()
      });
    }
    if (p == 'idag') {
      this.form.patchValue({
        startTidspunktDate: dayjs().startOf('day').toDate(),
        slutTidspunktDate: dayjs().add(5, 'minute').toDate()
      });
    }
    if (p == 'igaar') {
      this.form.patchValue({
        startTidspunktDate: dayjs().startOf('day').subtract(1, 'day').toDate(),
        slutTidspunktDate: dayjs()
          .startOf('day')
          .subtract(1, 'second')
          .toDate()
      });
    }
  }

  async onClickSearch() {
    this.validationMessage.set('');
    this.logService.logSearchParams = {
      ...(this.form.value as LogSearchParams),
      startTidspunkt: dayjs(this.form.value.startTidspunktDate).format(
        'YYYY-MM-DD HH:mm'
      ),
      slutTidspunkt: dayjs(this.form.value.slutTidspunktDate).format(
        'YYYY-MM-DD HH:mm'
      )
    };

    await this.logService.searchLog();
    if (this.logService.data().length == 0) {
      this.validationMessage.set('Ingen hits');
    }
  }

  onClickReset() {
    this.form.reset();
    this.form.patchValue({
      startTidspunktDate: dayjs().subtract(20, 'minute').toDate(),
      slutTidspunktDate: dayjs().add(5, 'minute').toDate()
    });
  }
}
