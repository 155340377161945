import { Component, effect, inject, input, signal, untracked } from '@angular/core';
import { RolleRettighederService } from './rolle-rettigheder.service';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { RolleDetailService } from '../rolle-detail/rolle-detail.service';
import { RettighederService } from '../rettigheder.service';
import { RolleRettighed } from './rolle-rettighed.model';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'app-rolle-rettigheder',
  imports: [
    CheckboxModule,
    FormsModule,
    ScrollPanelModule
  ],
  templateUrl: './rolle-rettigheder.component.html',
  styleUrl: './rolle-rettigheder.component.css'
})

export class RolleRettighederComponent {
  rolleId = input.required<number>();
  rolleDetailService = inject(RolleDetailService);
  rolleRettighederService = inject(RolleRettighederService);
  rettighedService = inject(RettighederService);
  rettighedData = signal<RettighedData[]>([]);

  constructor() {

    effect(async () => {
      if (this.rolleId() > 0) {
        await untracked(async () => {
          await this.rolleRettighederService.load({
            params: {
              filter: JSON.stringify({
                rolleId: this.rolleId()
              })

            }
          });
        });
      } else {
        this.rolleRettighederService.clear();
      }
      const _rettighedData: RettighedData[] = [];
      this.rettighedService.data().forEach((r) => {
        const rr = this.getRolleRettighed(r.name);
        _rettighedData.push({ ...r, rolleRettighed: rr, checked: !!rr });
      });
      this.rettighedData.set(_rettighedData);

    });
  }

  getRolleRettighed(rettighed: string): RolleRettighed | undefined {
    if (!this.rolleRettighederService.data()) return undefined;
    const found = this.rolleRettighederService.data().filter((rolleRettighed) => {
      return rolleRettighed.rettighed === rettighed;
    });
    if (found.length > 0) {
      return found[0];
    }
    return undefined;
  }

  grant(r: RettighedData) {
    if (r.checked) {
      if (this.rolleDetailService.changes[r.name]?.action === 'add') {
        delete this.rolleDetailService.changes[r.name];
      } else {
        this.rolleDetailService.changes[r.name] = { action: 'remove', id: r.rolleRettighed?.id };
      }
    } else {
      if (this.rolleDetailService.changes[r.name]?.action === 'remove') {
        delete this.rolleDetailService.changes[r.name];
      } else {
        this.rolleDetailService.changes[r.name] = { action: 'add', rettighed: r.name };
      }
    }
  }


}

interface RettighedData {
  name: string,
  description: string,
  checked: boolean;
  rolleRettighed?: RolleRettighed;
}

