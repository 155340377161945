import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
    selector: 'app-sidenav-layout',
    imports: [
        RouterOutlet,
        SidebarComponent,
        ScrollPanelModule
    ],
    templateUrl: './sidenav-layout.component.html',
    styleUrl: './sidenav-layout.component.css'
})
export class SidenavLayoutComponent {

}
