<div class="flex gap-2">
  <div class="flex-1 title">{{ title() }}</div>
  <div class="w-4 cursor-pointer">
    <i
      class="pi pi-copy"
      style="color: #49a3a8"
      (click)="copyToClipboard()"
    ></i>
  </div>
  <div class="w-4 cursor-pointer">
    <i
      class="pi pi-window-maximize"
      style="color: #49a3a8"
      (click)="openFullScreen()"
    ></i>
  </div>
</div>
<p-scroll-panel class="flex-1 overflow-hidden relative" styleClass="h-full">
  <pre>{{ content() }}</pre>
</p-scroll-panel>
<p-dialog
  [header]="title()"
  [modal]="true"
  [(visible)]="fullscreen"
  [style]="{ width: '80%', maxWidth: '600px;' }"
>
  <pre>{{ content() }}</pre>
</p-dialog>
