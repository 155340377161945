import { PercentPipe } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { ScrollPanel } from 'primeng/scrollpanel';
import { SatserService } from '../../../../core/satser/satser.service';
import { Pengeinstitut } from '../../pengeinstitut.model';
import { PengeinstitutIndstilling } from '../../pi-indstilling/pengeinstitutindstilling.model';

@Component({
  selector: 'app-pi-detail-investeringsvalg',
  imports: [PercentPipe, ScrollPanel],
  templateUrl: './pi-detail-investeringsvalg.component.html',
  styleUrl: './pi-detail-investeringsvalg.component.css',
})
export class PiDetailInvesteringsvalgComponent {
  indstillinger = input.required<PengeinstitutIndstilling>();
  pi = input.required<Pengeinstitut>();
  satserService = inject(SatserService);
}
