import { inject, Injectable, signal } from '@angular/core';
import { LogEntry } from './log.model';
import { LogSearchParams } from './log-search/log-search-params.model';
import { LogDetailCallsService } from './log-detail-calls/log-detail-calls.service';
import { LogDetailMessagesService } from './log-detail-messages/log-detail-messages.service';
import { LogDetailSsbService } from './log-detail-ssb/log-detail-ssb.service';
import { ApiRestService } from '../../core/api/api.rest.service';

@Injectable({
  providedIn: 'root',
})
export class LogService extends ApiRestService<LogEntry> {
  constructor() {
    super('/api/logsearch');
  }

  logDetailCallsService = inject(LogDetailCallsService);
  logDetailMessagesService = inject(LogDetailMessagesService);
  logDetailSsbService = inject(LogDetailSsbService);
  selectedLogEntry = signal<LogEntry | undefined>(undefined);
  activeTab = signal('search');
  private scrollPosition = 0;
  private readonly LOG_TABLE_QUERY_SELECTOR =
    '#logResultTable .p-datatable-table-container';

  logSearchParams: LogSearchParams = {};

  async searchLog() {
    this.scrollPosition = 0;
    this.selectedLogEntry.set(undefined);
    await this.load({ params: { ...this.logSearchParams } });
    if (this.data().length > 0) {
      this.activeTab.set('result');
    }
  }

  selectLogResultRow(logEntry: LogEntry) {
    this.selectLogEntry(logEntry);

    if (logEntry) {
      this.activeTab.set('detail');
    }
    this.saveLogresultScrollPosition();
  }

  selectLogEntry(logEntry: LogEntry) {
    this.selectedLogEntry.set(logEntry);

    const callsId = logEntry.parentRequestLogId || logEntry.id;
    this.logDetailCallsService.loadCalls(callsId);

    this.logDetailMessagesService.loadMessages(logEntry.id);
    if (logEntry.ssbLogEntryId) {
      this.logDetailSsbService.loadMessages(logEntry.ssbLogEntryId);
    } else {
      this.logDetailSsbService.clear();
    }
  }

  saveLogresultScrollPosition() {
    const tbl = document.querySelector(this.LOG_TABLE_QUERY_SELECTOR);
    if (tbl) {
      this.scrollPosition = tbl.scrollTop;
    }
  }

  restoreLogresultScrollPosition() {
    setTimeout(() => {
      const tbl = document.querySelector(this.LOG_TABLE_QUERY_SELECTOR);
      if (tbl) {
        tbl?.scrollTo({ top: this.scrollPosition });
      }
    }, 1);
  }
}
