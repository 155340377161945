import { FormGroup } from '@angular/forms';
import diff from 'microdiff';

export function validateAllFormControls(form: FormGroup) {

  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    control?.markAsTouched({ onlySelf: true });
    control?.markAsDirty({ onlySelf: true });
  });
}

export function getDiffAsText(oldValues: {}, newValues: {}) {
  let count = 0;
  let text = '';
  const diffs = diff(oldValues, newValues);
  diffs.forEach(d => {
    if (count > 0) {
      text = text + '\n';
    }
    text = text + d.path.join('.') + ': ';
    if (d.type === 'CREATE') {
      text = text + '=> ' + d.value;
    }
    if (d.type === 'REMOVE') {
      text = text + d.oldValue + ' =>';
    }
    if (d.type === 'CHANGE') {
      text = text + d.oldValue + ' => ' + d.value;
    }
    count++;
  });
  return text;

}
