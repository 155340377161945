import { Component, inject } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'primeng/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TextareaModule } from 'primeng/textarea';
import { AuthService } from '@core/auth/auth.service';
import { PensionsudbydereService } from '../pensionsudbydere.service';
import { PuHistorikComponent } from '../pu-historik/pu-historik.component';
import { PuDetailStamdataComponent } from './pu-detail-stamdata/pu-detail-stamdata.component';

@Component({
  selector: 'app-pu-detail',
  imports: [AccordionModule, ScrollPanelModule, PuHistorikComponent, Tab, TabList, TabPanel, TabPanels, Tabs,
    PuDetailStamdataComponent,
    ReactiveFormsModule, ButtonModule, DialogModule, TextareaModule, FormsModule],
  templateUrl: './pu-detail.component.html',
  styleUrl: './pu-detail.component.css'
})
export class PuDetailComponent {
  puService = inject(PensionsudbydereService);
  authService = inject(AuthService);


}
