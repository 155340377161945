import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { appConfig } from '../../app-config';

@Component({
  selector: 'app-system-page',
  imports: [ButtonModule],
  templateUrl: './system-pagecomponent.html',
  styleUrl: './system-pagecomponent.css',
})
export class SystemPagecomponent {
  appName = appConfig.appName;
}
