<div class="flex flex-col h-full">
  <div>
    <h1>Roller</h1>
  </div>
  @if (id()) {
    <div class="flex-1 2xl:overflow-hidden pb-4">
      <app-rolle-detail [id]="rolleId()!"></app-rolle-detail>
    </div>
  } @else {
    <div class="flex-1 overflow-hidden">
      <app-roller-grid [hidden]="id()!=undefined" class="h-full overflow-hidden"></app-roller-grid>
    </div>
  }
</div>
