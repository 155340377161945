import { Route } from '@angular/router';
import { Component, Type } from '@angular/core';
import { HomeComponent } from '@features/home/home.component';
import { NotFoundComponent } from '@core/pages/not-found/not-found.component';
import { TestComponent } from '@shared/test/test.component';
import { SidenavLayoutComponent } from '@core/layout/sidenav/sidenav-layout/sidenav-layout.component';
import { RettighedsType } from '@core/auth/rettighed.model';
import { LogComponent } from '@features/log/log.component';
import { UserProfileComponent } from '@core/pages/user-profile/user-profile.component';
import { BrugereMainComponent } from '@features/brugere/brugere-main/brugere-main.component';
import { RollerMainComponent } from '@features/roller/roller-main/roller-main.component';
import { PengeinstitutterComponent } from '@features/pengeinstitutter/pengeinstitutter.component';
import { PensionsudbydereComponent } from '@features/pensionsudbydere/pensionsudbydere.component';
import { RaadgivereComponent } from '@features/raadgivere/raadgivere.component';
import { DokumenterComponent } from '@features/dokumenter/dokumenter.component';
import { KunderComponent } from '@features/kunder/kunder.component';
import { OpkraevningslisterComponent } from '@features/opkraevningslister/opkraevningslister.component';
import { ProduktlisterComponent } from '@features/produktlister/produktlister.component';
import { RisikoopkraevningComponent } from '@features/risikoopkraevning/risikoopkraevning.component';
import { BatchComponent } from '@features/batch/batch.component';
import { DriftsmeddelelserComponent } from '@features/driftsmeddelelser/driftsmeddelelser.component';
import { FilerComponent } from '@features/filer/filer.component';
import { LetregisterComponent } from '@features/letregister/letregister.component';
import { LetpensiondkComponent } from '@features/letpensiondk/letpensiondk.component';
import { ManuelHelbredComponent } from '@features/manuel-helbred/manuel-helbred.component';
import { SsbKonfigurationComponent } from '@features/ssb-konfiguration/ssb-konfiguration.component';
import { SletRevisionComponent } from '@features/support/slet-revision/slet-revision.component';
import { VendUnderskriftComponent } from '@features/support/vend-underskrift/vend-underskrift.component';

export interface RouteData {
  layout?: Type<Component>;
  pageTitle?: string;
  rettighed?: RettighedsType;
}

interface CustomRoute extends Route {
  data?: RouteData;
  children?: CustomRoute[];
}

export const routes: CustomRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    data: {
      rettighed: RettighedsType.AppAdmin,
    },
  },
  {
    path: 'profile',
    component: UserProfileComponent,
  },
  {
    path: 'brugeradm',
    pathMatch: 'full',
    redirectTo: '/brugeradm/brugere',
  },
  {
    path: 'brugeradm/brugere',
    component: BrugereMainComponent,
    data: {
      rettighed: RettighedsType.AdminBruger,
    },
  },
  {
    path: 'brugeradm/roller',
    component: RollerMainComponent,
    data: {
      rettighed: RettighedsType.AdminRolle,
    },
  },
  {
    path: 'support',
    pathMatch: 'full',
    redirectTo: 'support/sletrevision',
  },
  {
    path: 'support/sletrevision',
    component: SletRevisionComponent,
    data: {
      rettighed: RettighedsType.AdminSupportSletRevision,
    },
  },
  {
    path: 'support/vendtilfysiskunderskrift',
    component: VendUnderskriftComponent,
    data: {
      rettighed: RettighedsType.AdminSupportVendTilFysiskUnderskrift,
    },
  },
  {
    path: 'log',
    component: LogComponent,
    data: {
      rettighed: RettighedsType.AdminLog,
    },
  },
  {
    path: 'pengeinstitutter',
    component: PengeinstitutterComponent,
    data: { rettighed: RettighedsType.AdminPengeinstitutter },
  },
  {
    path: 'pensionsudbydere',
    component: PensionsudbydereComponent,
    data: { rettighed: RettighedsType.AdminPensionsudbydere },
  },
  {
    path: 'raadgivere',
    component: RaadgivereComponent,
    data: { rettighed: RettighedsType.AdminRaadgivere },
  },
  {
    path: 'dokumenter',
    component: DokumenterComponent,
    data: { rettighed: RettighedsType.AdminDokumenter },
  },
  {
    path: 'Kunder',
    component: KunderComponent,
    data: { rettighed: RettighedsType.AdminKunder },
  },
  {
    path: 'opkraevningslister',
    component: OpkraevningslisterComponent,
    data: { rettighed: RettighedsType.AdminOpkraevningslister },
  },
  {
    path: 'produktlister',
    component: ProduktlisterComponent,
    data: { rettighed: RettighedsType.AdminProduktlister },
  },
  {
    path: 'risikoopkraevning',
    component: RisikoopkraevningComponent,
    data: { rettighed: RettighedsType.AdminRisikoopkraevning },
  },
  {
    path: 'batch',
    component: BatchComponent,
    data: { rettighed: RettighedsType.AdminBatch },
  },
  {
    path: 'driftsmeddelelser',
    component: DriftsmeddelelserComponent,
    data: { rettighed: RettighedsType.AdminDriftsmeddelelser },
  },
  {
    path: 'ssbkonfiguration',
    component: SsbKonfigurationComponent,
    data: { rettighed: RettighedsType.AdminSsbKonfiguration },
  },
  {
    path: 'filer',
    component: FilerComponent,
    data: { rettighed: RettighedsType.AdminFiler },
  },
  {
    path: 'manuelhelbred',
    component: ManuelHelbredComponent,
    data: { rettighed: RettighedsType.AdminManuelHelbred },
  },
  {
    path: 'letpensiondk',
    component: LetpensiondkComponent,
    data: { rettighed: RettighedsType.AdminLetpensionDk },
  },
  {
    path: 'letregister',
    component: LetregisterComponent,
    data: { rettighed: RettighedsType.AdminLetregister },
  },
  {
    path: 'test',
    component: TestComponent,
    data: {
      layout: SidenavLayoutComponent,
      pageTitle: 'Steen Oles side',
      rettighed: RettighedsType.DeveloperMode,
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
