import { Component, computed, effect, inject, signal } from '@angular/core';
import { ScrollPanel } from 'primeng/scrollpanel';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputText } from 'primeng/inputtext';
import { RaadgivereService } from '../raadgivere.service';
import { Button } from 'primeng/button';
import { RettighedsType } from '@core/auth/rettighed.model';
import { AuthService } from '@core/auth/auth.service';
import { Raadgiver } from '../raadgiver.model';
import { CheckboxModule } from 'primeng/checkbox';
import { BooleanPipe } from '@core/pipes/boolean.pipe';
import { DatePickerModule } from 'primeng/datepicker';
import dayjs from 'dayjs';
import { PengeinstitutSelectComponent } from '../../../shared/pengeinstitut-select/pengeinstitut-select.component';
import { MessageModule } from 'primeng/message';

@Component({
  selector: 'app-raadgiver-detail',
  imports: [
    ScrollPanel,
    ReactiveFormsModule,
    InputText,
    Button,
    CheckboxModule,
    BooleanPipe,
    DatePickerModule,
    PengeinstitutSelectComponent,
    MessageModule,
  ],
  templateUrl: './raadgiver-detail.component.html',
  styleUrl: './raadgiver-detail.component.css',
})
export class RaadgiverDetailComponent {
  raadgiverService = inject(RaadgivereService);
  authService = inject(AuthService);
  form: FormGroup;
  infoMessage = signal('');

  allowEdit = computed(() =>
    this.authService.hasRettighed(RettighedsType.AdminRaadgivereSkriv)
  );
  saving = signal(false);

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      fornavn: ['', { validators: [Validators.required] }],
      mellemnavne: [],
      efternavn: [],
      brugerId: ['', { validators: [Validators.required] }],
      pengeinstitutId: [''],
      oprettet: new FormControl<Date | undefined>(undefined, {
        validators: [Validators.required],
      }),
      inaktivering: [''],
      formueraadgiver: [''],
      letsikringTilSelvstaendigRaadgiver: [''],
      falRettighed: [''],
      email: ['', { validators: [Validators.required] }],
      telefonNummer: ['', { validators: [Validators.required] }],
      adresse: this.fb.group({
        id: [],
        conavn: [],
        bynavn: [],
        doer: [],
        postnr: [],
        etage: [],
        sted: [],
        vejnavn: [],
        vejnr: [],
      }),
    });

    effect(() => {
      const raadgiver = this.raadgiverService.selectedRaadgiver();
      if (!raadgiver) {
        this.form.reset();
        this.form.patchValue({
          oprettet: dayjs().toDate(),
        });
      } else {
        this.infoMessage.set('');
        this.form.setValue({
          fornavn: raadgiver.fornavn || '',
          mellemnavne: raadgiver.mellemnavne || '',
          efternavn: raadgiver.efternavn || '',
          brugerId: raadgiver.brugerId || '',
          pengeinstitutId: raadgiver.pengeinstitutId || '',
          oprettet: dayjs(raadgiver.oprettet).toDate(),
          inaktivering: raadgiver.inaktivering
            ? dayjs(raadgiver.inaktivering).toDate()
            : '',
          formueraadgiver: raadgiver.formueraadgiver || false,
          letsikringTilSelvstaendigRaadgiver:
            raadgiver.letsikringTilSelvstaendigRaadgiver || false,
          falRettighed: raadgiver.falRettighed || false,
          email: raadgiver.email || '',
          telefonNummer: raadgiver.telefonNummer || '',
          adresse: {
            id: raadgiver.adresse?.id,
            conavn: raadgiver.adresse?.conavn || '',
            bynavn: raadgiver.adresse?.bynavn || '',
            doer: raadgiver.adresse?.doer || '',
            postnr: raadgiver.adresse?.postnr || '',
            etage: raadgiver.adresse?.etage || '',
            sted: raadgiver.adresse?.sted || '',
            vejnavn: raadgiver.adresse?.vejnavn || '',
            vejnr: raadgiver.adresse?.vejnr || '',
          },
        });
      }
    });
  }

  async onClickSave() {
    await this.saveChanges();
  }

  async saveChanges() {
    if (this.form.invalid) {
      return;
    }

    this.saving.set(true);

    const raadgiver = this.raadgiverService.selectedRaadgiver();

    const newData = {
      ...raadgiver,
      ...this.form.value,
    } as Raadgiver;

    if (raadgiver?.id) {
      await this.raadgiverService.update(raadgiver.id, newData);
    } else {
      await this.raadgiverService.create(newData);
    }

    this.saving.set(false);
    this.infoMessage.set('Rådgiver gemt');
  }
}
