<div class="flex flex-col h-full">
  <p-toolbar>
    <ng-template pTemplate="start">
      <p-button routerLink="/brugeradm/roller" icon="pi pi-arrow-left" label="Tilbage til listen" class="mr-2" text
                severity="secondary" />
    </ng-template>
    <ng-template pTemplate="end">
      <p-button label="Gem rolle" (click)="onClickSave()" [loading]="rollerService.isLoading()" />
    </ng-template>
  </p-toolbar>
  <div class="flex-1 flex flex-wrap gap-4 mt-4 overflow-hidden">
    <p-panel styleClass="w-full w-[400px] h-full">
      <form [formGroup]="form">
        <div class="control">
          <label>Navn</label>
          <input pInputText formControlName="navn" />
          @if (navnIsInvalid) {
            <p class="error">Navn er påkrævet</p>
          }
        </div>
        <div class="control">
          <label>Beskrivelse</label>
          <textarea pTextarea formControlName="beskrivelse" rows="4"></textarea>
        </div>
        @if (validationMessage()) {
          <p-message [text]="validationMessage()" severity="error" />
        }
      </form>
    </p-panel>
    <div class="w-full 2xl:flex-1 border-1 rounded max-h-full overflow-hidden">
      <app-rolle-rettigheder [rolleId]="id()" />
    </div>
  </div>
</div>
