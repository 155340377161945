import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-letpensiondk',
    imports: [
        Message
    ],
    templateUrl: './letpensiondk.component.html',
    styleUrl: './letpensiondk.component.css'
})
export class LetpensiondkComponent {

}
