import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-test',
    imports: [
        RouterLink
    ],
    templateUrl: './test.component.html',
    styleUrl: './test.component.css'
})
export class TestComponent {

}
