import { inject, signal } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse } from './apiresponse.model';
import { appConfig } from '../app-config';
import { firstValueFrom } from 'rxjs';

export class ApiSimpleService<T> {
  private httpClient = inject(HttpClient);
  private _endpoint: string;

  data = signal<T[]>([]);
  _loading = signal(false);
  readonly isLoading = this._loading.asReadonly();
  _failed = signal(false);
  readonly isFailed = this._failed.asReadonly();
  _error = signal<unknown>(undefined);
  readonly error = this._error.asReadonly();

  latestLoadOptions: ApiRestGetOptions = {};

  constructor(endpoint: string) {
    this._endpoint = endpoint;
  }

  async load(options?: ApiRestGetOptions) {
    this._loading.set(true);
    this._failed.set(true);

    if (options) {
      this.latestLoadOptions = options;
    }

    try {
      let url = `${appConfig.apiRoot}${this._endpoint}`;
      const source = this.httpClient.get<T[]>(url, options);
      const response = await firstValueFrom(source);
      this.data.set(response);
      return response;
    } catch (error) {
      this._failed.set(true);
      this._error.set(error);
      return {} as T[];
    } finally {
      this._loading.set(false);
    }
  }

  async reload() {
    return await this.load(this.latestLoadOptions);
  }

  async getById(id: number) {
    this._loading.set(true);
    this._failed.set(true);
    try {
      const source = this.httpClient.get<T>(
        `${appConfig.apiRoot}${this._endpoint}/${id}`
      );
      return await firstValueFrom(source);
    } catch (error) {
      this._failed.set(true);
      this._error.set(error);
      return {} as ApiResponse<T>;
    } finally {
      this._loading.set(false);
    }
  }

  async create(value: T) {
    this._loading.set(true);
    this._failed.set(true);
    try {
      const source = this.httpClient.post<T>(
        `${appConfig.apiRoot}${this._endpoint}`,
        value
      );
      return await firstValueFrom(source);
    } catch (error) {
      this._failed.set(true);
      this._error.set(error);
      return {} as ApiResponse<T>;
    } finally {
      this._loading.set(false);
    }
  }

  async update(id: number, value: T) {
    this._loading.set(true);
    this._failed.set(true);
    try {
      const source = this.httpClient.put<T>(
        `${appConfig.apiRoot}${this._endpoint}/${id}`,
        value
      );
      return await firstValueFrom(source);
    } catch (error) {
      this._failed.set(true);
      this._error.set(error);
      return {} as ApiResponse<T>;
    } finally {
      this._loading.set(false);
    }
  }

  async remove(id: number) {
    this._loading.set(true);
    this._failed.set(true);
    try {
      const source = this.httpClient.delete<T>(
        `${appConfig.apiRoot}${this._endpoint}/${id}`
      );
      return await firstValueFrom(source);
    } catch (error) {
      this._failed.set(true);
      this._error.set(error);
      return {} as ApiResponse<T>;
    } finally {
      this._loading.set(false);
    }
  }

  clear() {
    this._loading.set(false);
    this._failed.set(false);
    this._error.set(undefined);
    this.data.set([]);
  }
}

export interface ApiRestGetOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  transferCache?:
    | {
        includeHeaders?: string[];
      }
    | boolean;
}
