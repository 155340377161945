import { Directive, Input } from '@angular/core';

interface TableRowTemplateContext<
  Titem extends object,
  TColumn extends object
> {
  $implicit: Titem;
  columns: TColumn[];
}

@Directive({
  selector: 'ng-template[appTableRow]',
})
export class TableRowDirective<Titem extends object, TColumn extends object> {
  @Input('appTableRow') items!: Titem[];
  @Input() rowColumns!: TColumn[];

  static ngTemplateContextGuard<
    TContextItem extends object,
    TContextColumn extends object
  >(
    dir: TableRowDirective<TContextItem, TContextColumn>,
    ctx: unknown
  ): ctx is TableRowTemplateContext<TContextItem, TContextColumn> {
    return true;
  }
}
