import { Component, input, signal } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanel } from 'primeng/scrollpanel';

@Component({
  selector: 'app-log-ssb-content',
  imports: [DialogModule, ScrollPanel],
  templateUrl: './log-ssb-content.component.html',
  styleUrl: './log-ssb-content.component.css',
})
export class LogSsbContentComponent {
  title = input.required<string>();
  content = input.required<string>();
  fullscreen = signal(false);

  openFullScreen() {
    this.fullscreen.set(true);
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.content()).then();
  }
}
