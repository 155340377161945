import { DatePipe } from '@angular/common';
import {
  Component,
  effect,
  ElementRef,
  inject,
  viewChild,
} from '@angular/core';
import { TableRowDirective } from '../../../core/primevue/directives/table-row.directive';
import { Table, TableModule, TableRowSelectEvent } from 'primeng/table';
import { PengeinstitutterService } from '../pengeinstitutter.service';

@Component({
  selector: 'app-pi-result',
  imports: [DatePipe, TableRowDirective, TableModule, DatePipe],
  templateUrl: './pi-result.component.html',
  styleUrl: './pi-result.component.css',
})
export class PiResultComponent {
  piService = inject(PengeinstitutterService);
  table = viewChild.required<Table>('piResultTable');

  constructor() {
    //Reset table sorting on dataload
    effect(() => {
      if (this.piService.isLoading()) {
        this.table().reset();
      }
    });
  }

  onRowSelect(e: TableRowSelectEvent) {
    this.piService.selectPiResultRow(e.data);
  }
}
