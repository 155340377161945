import { Component, inject } from '@angular/core';
import { LogService } from '../log.service';
import { LogDetailMessagesService } from './log-detail-messages.service';
import { TableModule } from 'primeng/table';
import { DatePipe } from '@angular/common';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';

@Component({
  selector: 'app-log-detail-messages',
  imports: [DatePipe, TableModule, TableRowDirective],
  templateUrl: './log-detail-messages.component.html',
  styleUrl: './log-detail-messages.component.css',
})
export class LogDetailMessagesComponent {
  logService = inject(LogService);
  logDetailMessagesService = inject(LogDetailMessagesService);
}
