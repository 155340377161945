import { Component, inject } from '@angular/core';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { PensionsudbydereService } from '../pensionsudbydere.service';

@Component({
  selector: 'app-pu-result',
  imports: [TableRowDirective, TableModule],
  templateUrl: './pu-result.component.html',
  styleUrl: './pu-result.component.css'
})
export class PuResultComponent {
  puService = inject(PensionsudbydereService);

  onRowSelect(e: TableRowSelectEvent) {
    this.puService.selectPuResultRow(e.data);
  }
}
