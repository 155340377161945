import { Component, effect, inject, input, signal, untracked } from '@angular/core';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Message } from 'primeng/message';
import { RollerService } from '../roller.service';
import { Rolle } from '../rolle.model';
import { RolleDetailService } from './rolle-detail.service';
import { RolleRettighederService } from '../rolle-rettigheder/rolle-rettigheder.service';
import { PanelModule } from 'primeng/panel';
import { TextareaModule } from 'primeng/textarea';
import { RolleRettighederComponent } from '../rolle-rettigheder/rolle-rettigheder.component';

@Component({
  selector: 'app-rolle-detail',
  imports: [
    ToolbarModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    SplitButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    Message,
    PanelModule,
    TextareaModule,
    RolleRettighederComponent,
    RouterLink
  ],
  templateUrl: './rolle-detail.component.html',
  styleUrl: './rolle-detail.component.css'
})
export class RolleDetailComponent {
  id = input.required<number>();

  rollerService = inject(RollerService);
  rolleDetailService = inject(RolleDetailService);
  rolleRettighedService = inject(RolleRettighederService);
  router = inject(Router);

  rolle = signal<Rolle | {}>({});
  validationMessage = signal<string | undefined>(undefined);

  constructor() {
    effect(async () => {
      if (this.id() > 0) {
        await untracked(async () => {
          const response = await this.rollerService.getById(this.id());
          this.rolle.set(response.data);
          this.form.patchValue(response.data);
        });
      } else {
        this.rolle.set({});
      }
    });
  }

  form = new FormGroup({
    navn: new FormControl('', {
      validators: [Validators.required]
    }),
    beskrivelse: new FormControl('')
  });

  get navnIsInvalid() {
    return (
      this.form.controls.navn.touched &&
      this.form.controls.navn.dirty &&
      this.form.controls.navn.invalid
    );
  }

  async onClickSave() {
    this.validationMessage.set(undefined);
    if (this.form.invalid) {
      return;
    }
    const newData = {
      ...this.rolle(),
      ...this.form.value
    } as Rolle;

    let response = await this.rolleDetailService.saveRolle(this.id(), newData);
    await this.rolleDetailService.saveRolleRettigheder(response.data.id);
    if (response.success) {
      await this.rollerService.reload();
      await this.router.navigate(['/brugeradm/roller']);
    } else {
      this.validationMessage.set(response.message);
    }

  }
}
