{
  "da": {
    "accept": "Ja",
    "addRule": "Tilføj regel",
    "am": "am",
    "apply": "Anvend",
    "cancel": "Annuller",
    "choose": "Vælg",
    "chooseDate": "Vælg dato",
    "chooseMonth": "Vælg måned",
    "chooseYear": "Vælg år",
    "clear": "Ryd",
    "completed": "Afsluttet",
    "contains": "Indeholder",
    "custom": "Brugerdefineret",
    "dateAfter": "Dato er efter",
    "dateBefore": "Dato er før",
    "dateFormat": "dd/mm-yy",
    "dateIs": "Dato er",
    "dateIsNot": "Dato er ikke",
    "dayNames": [
      "Søndag",
      "Mandag",
      "Tirsdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lørdag"
    ],
    "dayNamesMin": [
      "Sø",
      "Ma",
      "Ti",
      "On",
      "To",
      "Fr",
      "Lø"
    ],
    "dayNamesShort": [
      "Søn",
      "Man",
      "Tir",
      "Ons",
      "Tor",
      "Fre",
      "Lør"
    ],
    "emptyFilterMessage": "Ingen resultater fundet",
    "emptyMessage": "Ingen tilgængelige muligheder",
    "emptySearchMessage": "Ingen resultater fundet",
    "emptySelectionMessage": "Ingen valgte elementer",
    "endsWith": "Slutter med",
    "equals": "Lig med",
    "fileSizeTypes": [
      "B",
      "KB",
      "MB",
      "GB",
      "TB",
      "PB",
      "EB",
      "ZB",
      "YB"
    ],
    "filter": "Filtrer",
    "firstDayOfWeek": 0,
    "gt": "Større end",
    "gte": "Større end eller lig med",
    "lt": "Mindre end",
    "lte": "Mindre end eller lig med",
    "matchAll": "Match alle",
    "matchAny": "Match nogen",
    "medium": "Medium",
    "monthNames": [
      "Januar",
      "Februar",
      "Marts",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "December"
    ],
    "monthNamesShort": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec"
    ],
    "nextDecade": "Næste årti",
    "nextHour": "Næste time",
    "nextMinute": "Næste minut",
    "nextMonth": "Næste måned",
    "nextSecond": "Næste sekund",
    "nextYear": "Næste år",
    "noFilter": "Ingen filtrering",
    "notContains": "Indeholder ikke",
    "notEquals": "Ikke lig med",
    "now": "Nu",
    "passwordPrompt": "Indtast adgangskode",
    "pending": "Afventer",
    "pm": "pm",
    "prevDecade": "Forrige årti",
    "prevHour": "Forrige time",
    "prevMinute": "Forrige minut",
    "prevMonth": "Forrige måned",
    "prevSecond": "Forrige sekund",
    "prevYear": "Forrige år",
    "reject": "Nej",
    "removeRule": "Fjern regel",
    "searchMessage": "{0} resultater er tilgængelige",
    "selectionMessage": "{0} elementer valgt",
    "showMonthAfterYear": false,
    "startsWith": "Starter med",
    "strong": "Stærk",
    "today": "I dag",
    "upload": "Upload",
    "weak": "Svag",
    "weekHeader": "Uge",
    "aria": {
      "cancelEdit": "Annullér ændringer",
      "close": "Luk",
      "collapseLabel": "Bryder sammen",
      "collapseRow": "Række foldet sammen",
      "editRow": "Redigér række",
      "expandLabel": "Udvide",
      "expandRow": "Række udvidet",
      "falseLabel": "Falsk",
      "filterConstraint": "Filterbegrænsning",
      "filterOperator": "Filteroperator",
      "firstPageLabel": "Første side",
      "gridView": "Gittervisning",
      "hideFilterMenu": "Skjul filtermenu",
      "jumpToPageDropdownLabel": "Skift til sidenummer",
      "jumpToPageInputLabel": "Skift til sidenummer",
      "lastPageLabel": "Sidste side",
      "listView": "Listevisning",
      "moveAllToSource": "Flyt alle til kilde",
      "moveAllToTarget": "Flyt alle til mål",
      "moveBottom": "Flyt til bunden",
      "moveDown": "Flyt ned",
      "moveTop": "Flyt til top",
      "moveToSource": "Flyt til kilde",
      "moveToTarget": "Flyt til mål",
      "moveUp": "Flyt op",
      "navigation": "Navigation",
      "next": "Næste",
      "nextPageLabel": "Næste side",
      "nullLabel": "Ikke valgt",
      "otpLabel": "Indtast engangskodeordstegn {0}",
      "pageLabel": "Side {page}",
      "passwordHide": "Skjul adgangskode",
      "passwordShow": "Vis adgangskode",
      "previous": "Forrige",
      "previousPageLabel": "Forrige side",
      "removeLabel": "Fjerne",
      "rotateLeft": "Roter mod venstre",
      "rotateRight": "Roter mod højre",
      "rowsPerPageLabel": "Rækker pr. side",
      "saveEdit": "Gem ændringer",
      "scrollTop": "Rul til toppen",
      "selectAll": "Alle elementer valgt",
      "selectLabel": "Vælg",
      "selectRow": "Række valgt",
      "showFilterMenu": "Vis filtermenu",
      "slide": "Slide",
      "slideNumber": "{slideNumber}",
      "star": "1 stjerne",
      "stars": "{star} stjerner",
      "trueLabel": "Sandt",
      "unselectAll": "Alle elementer fjernet",
      "unselectLabel": "Fravælg",
      "unselectRow": "Række fjernet",
      "zoomImage": "Zoom ind på billede",
      "zoomIn": "Zoom ind",
      "zoomOut": "Zoom ud"
    }
  }
}
