<h1>Vend dokument til fysisk underskrift</h1>
<form [formGroup]="form">
  <div class="control required">
    <label>Cpr</label>
    <input pInputText autofocus formControlName="cpr" />
    @if (cprIsInvalid) {
      <p class="error">Cpr nr. er påkrævet</p>
    }
  </div>
  <div class="control required">
    <label>Dokument id</label>
    <input pInputText formControlName="dokumentId" />
    @if (dokumentIdIsInvalid) {
      <p class="error">Dokument id er påkrævet</p>
    }
  </div>
  <div class="flex gap-2 mt-2 mb-4">
    <p-button label="Valider dokument" [disabled]="!form.valid" [loading]="loading()"
              (click)="vendTilFysiskUnderskrift(0)" />
    <p-button label="Vend til fysisk" [disabled]="!vendingTilladt()" [loading]="loading()"
              (click)="vendTilFysiskUnderskrift(1)" />
    <p-button label="Nulstil" (click)="fortryd()" outlined />
  </div>
  @if (validationMessage()) {
    <p-message [text]="validationMessage()" severity="error" class="max-w-32" />
  }
  @if (besked()) {
    <p-message [text]="besked()" />
  }
</form>
