@let pi = piService.selectedPi();
<div class="flex flex-col gap-2 h-full overflow-hidden">
  <form [formGroup]="form" class="flex-1 overflow-hidden">
    @if (pi) {
      <p-tabs
        value="stamdata"
        class="h-full border-1 overflow-hidden"
      >
        <p-tablist>
          <p-tab value="stamdata">Stamdata</p-tab>
          <p-tab value="historik">Historik</p-tab>
          <p-tab value="piIndstillinger">PI Indstillinger</p-tab>
          <p-tab value="indbetalingsaftale">Indbetalingsaftale</p-tab>
          <p-tab value="investeringsvalg">Investeringsvalg</p-tab>
          <p-tab value="begunstigelse">Begunstigelse</p-tab>
          <p-tab value="overloeb">Overløb</p-tab>
        </p-tablist>
        <p-tabpanels class="overflow-hidden">
          <p-tabpanel value="stamdata">
            <app-pi-detail-stamdata [pi]="pi" [formGroup]="form" />
          </p-tabpanel>
          <p-tabpanel value="historik">
            <app-pi-historik />
          </p-tabpanel>
          <p-tabpanel value="piIndstillinger">
            <app-pi-detail-indstillinger [indstillinger]="indstillinger()" [pi]="pi" />
          </p-tabpanel>
          <p-tabpanel value="indbetalingsaftale">
            <app-pi-detail-indbetalingsaftale [indstillinger]="indstillinger()" />
          </p-tabpanel>
          <p-tabpanel value="investeringsvalg">
            <app-pi-detail-investeringsvalg [indstillinger]="indstillinger()" [pi]="pi" />
          </p-tabpanel>
          <p-tabpanel value="begunstigelse">
            <app-pi-detail-begunstigelse [indstillinger]="indstillinger()" />
          </p-tabpanel>
          <p-tabpanel value="overloeb">
            <app-pi-detail-overloeb [indstillinger]="indstillinger()" />
          </p-tabpanel>
        </p-tabpanels>
      </p-tabs>
    }
  </form>
  @if (allowEdit()) {
    <p-button label="Gem ændringer" icon="pi pi-save" (click)="onClickSave()" />
  }
</div>
<p-dialog header="Gem ændringer" [modal]="true" [(visible)]="showSaveDialog" [style]="{ width: '25rem' }">
  <div class="control">
    <label for="username">Kommentar</label>
    <textarea [(ngModel)]="kommentar" pTextarea id="username" autocomplete="off"></textarea>
  </div>
  <div class="flex justify-end gap-2">
    <p-button label="Gem" (click)="saveChanges()" [disabled]="!saveDialogIsValid()" icon="pi pi-save"
              [loading]="saving()" />
  </div>
</p-dialog>
