<div class="flex flex-col h-full">
  <p-toolbar>
    <ng-template pTemplate="start">
      <p-button routerLink="/brugeradm/brugere" [queryParams]="{id:-1}"
                icon="pi pi-plus"
                label="Opret bruger"
                class="mr-2"
      />
    </ng-template>
    <ng-template pTemplate="end">
      <div class="flex gap-2">
        <p-select [options]="organisationService.data()"
                  [(ngModel)]="searchOrganisationId"
                  [showClear]="true"
                  (onChange)="onSearch()"
                  optionValue="id"
                  optionLabel="navn"
                  placeholder="Organisation"
                  class="w-64" />
        <p-select [options]="rollerService.data()"
                  [(ngModel)]="searchRolleId"
                  [showClear]="true"
                  (onChange)="onSearch()"
                  optionValue="id"
                  optionLabel="navn"
                  placeholder="Rolle"
                  class="w-64" />
        <p-iconfield>
          <p-inputicon styleClass="pi pi-search" />
          <input type="text" pInputText [(ngModel)]="searchText" (keyup.enter)="onSearch()" />
        </p-iconfield>
      </div>
    </ng-template>
  </p-toolbar>
  <p-table [value]="brugere()"
           showGridlines
           stripedRows
           [scrollable]="true"
           scrollHeight="flex"
           [loading]="brugereService.isLoading()"
           class="flex-1 overflow-hidden p-datatable-sm mt-4">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="fornavn">
          Fornavn
          <p-sortIcon field="fornavn" />
        </th>
        <th pSortableColumn="efternavn">
          Efternavn
          <p-sortIcon field="efternavn" />
        </th>
        <th pSortableColumn="email">
          Email
          <p-sortIcon field="email" />
        </th>
        <th pSortableColumn="organisation.navn">
          Organisation
          <p-sortIcon field="organisation.navn" />
        </th>
        <th pSortableColumn="sidsteLogin" style="width:150px">
          Sidste login
          <p-sortIcon field="sidsteLogin" />
        </th>
      </tr>
    </ng-template>
    <ng-template [appTableRow]="brugere()" pTemplate="body" let-bruger>
      <tr [routerLink]="['/brugeradm', 'brugere']" [queryParams]="{id:bruger.id}" class="cursor-pointer">
        <td>{{ bruger.fornavn }}</td>
        <td>{{ bruger.efternavn }}</td>
        <td>{{ bruger.email }}</td>
        <td>{{ bruger.organisation?.navn }}</td>
        <td class="numbers">{{ bruger.sidsteLogin | date:'dd-MM-YYYY HH:mm' }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
