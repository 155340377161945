import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'boolean' })
export class BooleanPipe implements PipeTransform {
  transform(value: boolean | undefined): string | undefined {
    if (value === undefined)
      return undefined;
    return value ? 'Ja' : 'Nej';
  }
}
