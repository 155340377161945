import { Injectable } from '@angular/core';
import { ApiSimpleService } from '@core/api/api.simple.service';
import { LogEntry } from '../log.model';

@Injectable({
  providedIn: 'root',
})
export class LogDetailCallsService extends ApiSimpleService<LogEntry> {
  constructor() {
    super('/api/logsearch/calls');
  }

  loadCalls(requestLogId: number) {
    this.load({ params: { requestLogId: requestLogId } });
  }
}
