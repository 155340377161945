import { Component, inject } from '@angular/core';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { RaadgivereService } from '../raadgivere.service';
import { DatePipe } from '@angular/common';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';

@Component({
  selector: 'app-raadgiver-result',
  imports: [TableModule, DatePipe, TableRowDirective],
  templateUrl: './raadgiver-result.component.html',
  styleUrl: './raadgiver-result.component.css'
})
export class RaadgiverResultComponent {
  raadgiverService = inject(RaadgivereService);

  onRowSelect(e: TableRowSelectEvent) {
    this.raadgiverService.selectRaadgiverResultRow(e.data);
  }
}
