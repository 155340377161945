<h1>Profil</h1>
<div>{{ currentUser().fornavn }} {{ currentUser().efternavn }} ({{ currentUser().email }})</div>
<p-button label="Log ud" (click)="onClickLogout()"></p-button>
<p-divider></p-divider>
<h2>Rettigheder</h2>
<ul>
  @for (r of currentUser().rettigheder; track r) {
    <li>{{ r }}</li>
  }
</ul>
