<p>test4 works!</p>
<a routerLink="/test1">Link til test1</a><br>
<a routerLink="/test5">Link til test5</a><br>
<a routerLink="/test6">Link til test6</a><br>
<a routerLink="/test4">Link til test4</a><br>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div><div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div><div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quis sit voluptatem! Accusamus eaque eos illo
  ipsa maiores minus nisi quibusdam tenetur vel? Facilis illum in ipsam nobis pariatur quibusdam?
</div>
