import { Component, input } from '@angular/core';
import { BooleanPipe } from '@core/pipes/boolean.pipe';
import { ScrollPanel } from 'primeng/scrollpanel';
import { PengeinstitutIndstilling } from '../../pi-indstilling/pengeinstitutindstilling.model';

@Component({
  selector: 'app-pi-detail-overloeb',
  imports: [
    BooleanPipe,
    ScrollPanel
  ],
  templateUrl: './pi-detail-overloeb.component.html',
  styleUrl: './pi-detail-overloeb.component.css'
})
export class PiDetailOverloebComponent {
  indstillinger = input.required<PengeinstitutIndstilling>();

}
