<div class="flex flex-col h-full">
  <div>
    <h1>Brugere</h1>
  </div>
  @if (id()) {
    <div class="flex-1 2xl:overflow-hidden pb-4">
      <app-bruger-detail [id]="brugerId()!"></app-bruger-detail>
    </div>
  } @else {
    <div class="flex-1 overflow-hidden">
      <app-brugere-grid [hidden]="id()!=undefined"></app-brugere-grid>
    </div>
  }
</div>
