import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-filer',
    imports: [
        Message
    ],
    templateUrl: './filer.component.html',
    styleUrl: './filer.component.css'
})
export class FilerComponent {

}
