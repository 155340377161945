import { inject, Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { RettighedsType } from '../auth/rettighed.model';
import { NavigationModel } from './navigation.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private router = inject(Router);
  authService = inject(AuthService);

  getMenuItems() {
    return this.navigationItems.filter((x) => {
      if (x.rettighed) {
        return this.authService.hasRettighed(x.rettighed);
      }
      return true;
    });
  }

  private navigationItems: NavigationModel[] = [
    {
      label: 'Brugeradministration',
      routerLink: '/brugeradm',
      items: [
        {
          label: 'Brugere',
          routerLink: '/brugeradm/brugere',
          rettighed: RettighedsType.AdminBruger,
        },
        {
          label: 'Roller',
          routerLink: '/brugeradm/roller',
          rettighed: RettighedsType.AdminRolle,
        },
      ],
    },
    {
      label: 'Forretningssupport',
      rettighed: RettighedsType.AdminSupportSletRevision,
      routerLink: '/support',
      items: [
        {
          label: 'Slet revision',
          routerLink: '/support/sletrevision',
          rettighed: RettighedsType.AdminSupportSletRevision,
        },
        {
          label: 'Vend til fysisk underskrift',
          routerLink: '/support/vendtilfysiskunderskrift',
          rettighed: RettighedsType.AdminSupportVendTilFysiskUnderskrift,
        },
      ],
    },
    {
      label: 'Log',
      routerLink: '/log',
      rettighed: RettighedsType.AdminLog,
    },
    {
      label: 'Pengeinstitutter',
      routerLink: '/pengeinstitutter',
      rettighed: RettighedsType.AdminPengeinstitutter,
    },
    {
      label: 'Pensionsudbydere',
      routerLink: '/pensionsudbydere',
      rettighed: RettighedsType.AdminPensionsudbydere,
    },
    {
      label: 'Rådgivere',
      routerLink: '/raadgivere',
      rettighed: RettighedsType.AdminRaadgivere,
    },
    {
      label: 'Dokumenter',
      routerLink: '/dokumenter',
      rettighed: RettighedsType.AdminDokumenter,
    },
    {
      label: 'Kunder',
      routerLink: '/Kunder',
      rettighed: RettighedsType.AdminKunder,
    },
    {
      label: 'Opkrævningslister',
      routerLink: '/opkraevningslister',
      rettighed: RettighedsType.AdminOpkraevningslister,
    },
    {
      label: 'Produktlister',
      routerLink: '/produktlister',
      rettighed: RettighedsType.AdminProduktlister,
    },
    {
      label: 'Risikoopkrævning',
      routerLink: '/risikoopkraevning',
      rettighed: RettighedsType.AdminRisikoopkraevning,
    },
    {
      label: 'Batch',
      routerLink: '/batch',
      rettighed: RettighedsType.AdminBatch,
    },
    {
      label: 'Driftsmeddelelser',
      routerLink: '/driftsmeddelelser',
      rettighed: RettighedsType.AdminDriftsmeddelelser,
    },
    {
      label: 'SSB Konfiguration',
      routerLink: '/ssbkonfiguration',
      rettighed: RettighedsType.AdminSsbKonfiguration,
    },
    {
      label: 'Filer',
      routerLink: '/filer',
      rettighed: RettighedsType.AdminFiler,
    },
    {
      label: 'Manuel Helbredscore',
      routerLink: '/manuelhelbred',
      rettighed: RettighedsType.AdminManuelHelbred,
    },
    {
      label: 'Letpension.dk',
      routerLink: '/letpensiondk',
      rettighed: RettighedsType.AdminLetpensionDk,
    },
    {
      label: 'Letregister',
      routerLink: '/letregister',
      rettighed: RettighedsType.AdminLetregister,
    },
    {
      label: 'Visual guide',
      routerLink: '/visualguide',
      rettighed: RettighedsType.DeveloperMode,
    },
    {
      label: 'Test page',
      routerLink: '/test',
      rettighed: RettighedsType.DeveloperMode,
    },
  ];

  getProfileMenuItems(): NavigationModel[] {
    return [
      {
        icon: 'pi pi-user',
        label: 'Profil',
        routerLink: '/profile',
      },
    ];
  }
}
