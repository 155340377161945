@let i = indstillinger();
<p-scroll-panel styleClass="h-full">
  <table class="detail-table">
    <tr>
      <td>Senest opdateret</td>
      <td>{{ i.senestOpdateret | date:'dd-MM-YYYY' }}</td>
    </tr>
    <tr>
      <td>Arbejdsgiver hentes fra ITC</td>
      <td>{{ i.arbejdsgiverHentesFraItc | boolean }}</td>
    </tr>
    <tr>
      <td>Arkivering i Letpension</td>
      <td>{{ i.arkiveringIletpension | boolean }}</td>
    </tr>
    <tr>
      <td>Effektuerings batch dage</td>
      <td>{{ i.effektueringBatchDage }}</td>
    </tr>
    <tr>
      <td>Klokkeslet for slutdag</td>
      <td>{{ i.slutDagKlokkeslaet | date : 'HH:mm' }}</td>
    </tr>
    <tr>
      <td>Tilbud tilbagekaldelse anvendelse</td>
      <td>{{ i.tilbudTilbagekaldelseAnvendelse | boolean }}</td>
    </tr>
    <tr>
      <td>Indlæsning af rådgivere fra PI</td>
      <td>{{ pi().raadgivereHentesFraItc | boolean }}</td>
    </tr>
    <tr>
      <td>Mødekorrespondance anvendelse</td>
      <td>{{ i.moedeKorrespondanceAnvendelse }}</td>
    </tr>
    <tr>
      <td>Arbejdsgiver søgning med referencenummer</td>
      <td>{{ i.arbejdsgiverSoegningMedRefNr | boolean }}</td>
    </tr>
    <tr>
      <td>Produkt salg mulig</td>
      <td>{{ i.produktsalgMulig | boolean }}</td>
    </tr>
    <tr>
      <td>Rediger rater under udbetaling</td>
      <td>{{ i.redigeringAfRateUnderUdbetaling | boolean }}</td>
    </tr>
    <tr>
      <td>Bestandrapportering</td>
      <td>{{ i.letsikringBestandRapportering }}</td>
    </tr>
    <tr>
      <td>Redigering af relationer fra ITC</td>
      <td>{{ i.redigeringAfRelationerFraItc | boolean }}</td>
    </tr>
    <tr>
      <td>Barn skal kendes af ITC</td>
      <td>{{ i.barnSkalKendesIitc | boolean }}</td>
    </tr>
    <tr>
      <td>Pantsætning af krydsliv muligt</td>
      <td>{{ i.pantsaetningAfKrydslivMulig | boolean }}</td>
    </tr>
    <tr>
      <td>Benytter digital signering</td>
      <td>{{ i.benytterDigitalSignering | boolean }}</td>
    </tr>
    <tr>
      <td>Behovsprocent pension</td>
      <td>{{ i.behovsProcentPension | number }}%</td>
    </tr>
    <tr>
      <td>Behovsprocent pension efter skifte</td>
      <td>{{ i.behovsProcentPensionEfterSkifte | number }}%</td>
    </tr>
    <tr>
      <td>Behovsprocent aldersskift</td>
      <td>{{ i.behovsProcentPensionSkifteAlder | number }} år</td>
    </tr>
  </table>
  <br />
  <table class="detail-table">
    <tr>
      <td class="detail-label">Anbefaling ved død</td>
      <td></td>
    </tr>
    <tr>
      <td>Behovsprocent død</td>
      <td>? %</td>
    </tr>
  </table>
  <table class="detail-table bordered">
    <tr>
      <th colspan="2"></th>
      <th [attr.colspan]="aldersgrupper().length">Med gæld, negativ nettoformue</th>
      <th [attr.colspan]="aldersgrupper().length">Positiv nettoformue</th>
    </tr>
    <tr>
      <th>Aldersgruppe</th>
      <th></th>
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <th>{{ aldersgruppe.start }}-{{ aldersgruppe.slut }}</th>
      }
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <th>{{ aldersgruppe.start }}-{{ aldersgruppe.slut }}</th>
      }
    </tr>
    <tr>
      <td>Død - antal år løn erstattes</td>
      <td>Gift / samboende</td>
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <td>{{ getAnbefalingDoed(aldersgruppe.id, 'Partner', false)|json }}</td>
      }
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <td>{{ getAnbefalingDoed(aldersgruppe.id, 'Partner', true) }}</td>
      }
    </tr>
    <tr>
      <td>Død - antal år løn erstattes</td>
      <td>Enlig</td>
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <td>{{ getAnbefalingDoed(aldersgruppe.id, 'EnligUdenBoern', false) }}</td>
      }
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <td>{{ getAnbefalingDoed(aldersgruppe.id, 'EnligUdenBoern', true) }}</td>
      }
    </tr>
    <tr>
      <td>Død - antal år løn erstattes</td>
      <td>Enlig m/børn u/21</td>
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <td>{{ getAnbefalingDoed(aldersgruppe.id, 'EnligMedBoern', false) }}</td>
      }
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <td>{{ getAnbefalingDoed(aldersgruppe.id, 'EnligMedBoern', true) }}</td>
      }
    </tr>
  </table>
  <br />
  <table>
    <tr>
      <td class="detail-label">Anbefaling ved sygdom</td>
    </tr>
  </table>
  <table class="detail-table bordered">
    <tr>
      <th>Lønerstatningsprocent</th>
      <th [attr.colspan]="aldersgrupper().length">Med gæld, negativ nettoformue</th>
      <th [attr.colspan]="aldersgrupper().length">Positiv nettoformue</th>
    </tr>
    <tr>
      <th>Aldersgruppe</th>
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <th>{{ aldersgruppe.start }}-{{ aldersgruppe.slut }}</th>
      }
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <th>{{ aldersgruppe.start }}-{{ aldersgruppe.slut }}</th>
      }
    </tr>
    @for (beloebsgruppe of beloebsgrupper(); track beloebsgruppe.id) {
      <tr>
        <td>{{ beloebsgruppe.start | number:'1.0' }}-{{ beloebsgruppe.slut | number:'1.0' }}</td>
        @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
          <td>{{ getAnbefalingSygdom(aldersgruppe.id, beloebsgruppe.id, false) }}%</td>
        }
        @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
          <td>{{ getAnbefalingSygdom(aldersgruppe.id, beloebsgruppe.id, true) }}%</td>
        }

      </tr>
    }
  </table>
  <br />
  <table>
    <tr>
      <td class="detail-label">Anbefaling ved kritisk sygdom</td>
    </tr>
  </table>
  <table class="detail-table bordered">
    <tr>
      <th></th>
      <th [attr.colspan]="aldersgrupper().length">Med gæld, negativ nettoformue</th>
      <th [attr.colspan]="aldersgrupper().length">Positiv nettoformue</th>
    </tr>
    <tr>
      <th>Aldersgruppe</th>
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <th>{{ aldersgruppe.start }}-{{ aldersgruppe.slut }}</th>
      }
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <th>{{ aldersgruppe.start }}-{{ aldersgruppe.slut }}</th>
      }
    </tr>
    <tr>
      <td>Dækningssum i kr.</td>
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <td>{{ getAnbefalingKritiskSygdom(aldersgruppe.id, false) }}</td>
      }
      @for (aldersgruppe of aldersgrupper(); track aldersgruppe.id) {
        <td>{{ getAnbefalingKritiskSygdom(aldersgruppe.id, true) }}</td>
      }
    </tr>
  </table>
  <br />
  <table class="detail-table">
    <tr>
      <td class="detail-label">Anbefaling af helbred</td>
      <td>{{ anbefalingHelbred() | boolean }}</td>
    </tr>
    <tr>
      <td class="detail-label">Anbefaling af barn ved død</td>
      <td>{{ anbefalingBarnVedDoed() | boolean }}</td>
    </tr>
  </table>
  <br />
  <table class="detail-table">
    <tr>
      <td>Kunden kan oprette Lettilbud</td>
      <td>{{ i.kanOpretteLettilbud | boolean }}</td>
    </tr>
    <tr>
      <td>Førtidspension på Letforslag</td>
      <td>{{ i.letforslagFoertidspension }}</td>
    </tr>
    <tr>
      <td>Folkepensiontillæg medregnes på Letforslag</td>
      <td>{{ i.medregnFolkepensionTillaegLetforslag | boolean }}</td>
    </tr>
    <tr>
      <td>Letportal visning</td>
      <td>{{ i.letportalVisning }}</td>
    </tr>
    <tr>
      <td>Letportal kontakt kunde mulig</td>
      <td>{{ i.kundeKontaktAnmodningMulig | boolean }}</td>
    </tr>
    <tr>
      <td>Hjemmeside</td>
      <td>{{ i.website }}</td>
    </tr>
    <tr>
      <td>Henvisning</td>
      <td>{{ i.godSkikPlaceringPaaWebsite }}</td>
    </tr>
    <tr>
      <td>Alderopsparing AMB fratrækkes</td>
      <td>{{ i.aldersOpsparingFratraekAmb | boolean }}</td>
    </tr>
    <tr>
      <td>Overgår til nye samfundsforudsætninger</td>
      <td>{{ i.overgaarTilNyeSamfundsForudsaetninger | date:'dd-MM-YYYY' }}</td>
    </tr>
    <tr>
      <td>Adgang til Klima investering</td>
      <td>{{ i.adgangTilKlimaInvesteringer | boolean }}</td>
    </tr>
    <tr>
      <td>Rådgivers kontaktopl. på FAL-breve</td>
      <td>{{ i.brugRaadgiverSomAfsenderPaaFALBreve | boolean }}</td>
    </tr>
    <tr>
      <td>Adgang til at oprette LTS aftaler</td>
      <td>{{ i.adgangTilLetsikringTilSelvstaendige | boolean }}</td>
    </tr>
    <tr>
      <td>Adgang til at oprette LTS aftaler</td>
      <td>{{ i.adgangTilLetsikringTilSelvstaendige | boolean }}</td>
    </tr>
    <tr>
      <td>Oprettelse af nyt sygdomsprodukt</td>
      <td>{{ i.opretNytSygdomsProdukt | boolean }}</td>
    </tr>
    <tr>
      <td>Adgang til Dan forslag til dialog</td>
      <td>{{ i.adgangTilTilbudsDanAutomatiskForslag | boolean }}</td>
    </tr>
  </table>
</p-scroll-panel>
