<div class="flex flex-col h-full">
  <div>
    <h1>Pensionsudbydere</h1>
  </div>
  <p-tabs
    [(value)]="puService.activeTab"
    class="flex-1 border-1 overflow-hidden"
  >
    <p-tablist>
      <p-tab value="search"><i class="pi pi-search"></i> Søgning</p-tab>
      @if (puService.data().length > 0) {
        <p-tab value="result" (click)="onSearchResultClick()"
        ><i class="pi pi-list"></i> Resultat
        </p-tab
        >
      }
      @if (puService.selectedPu()) {
        <p-tab value="detail"><i class="pi pi-file"></i> Detaljer</p-tab>
      }
    </p-tablist>
    <p-tabpanels class="overflow-hidden">
      <p-tabpanel value="search">
        <app-pu-search />
      </p-tabpanel>
      <p-tabpanel value="result">
        <app-pu-result />
      </p-tabpanel>
      <p-tabpanel value="detail">
        <app-pu-detail />
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>
</div>
