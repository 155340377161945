import { Component, inject } from '@angular/core';
import { PensionsudbyderHistorikService } from './pu-historik.service';
import { DatePipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';

@Component({
  selector: 'app-pu-historik',
  imports: [
    DatePipe,
    TableModule,
    TableRowDirective
  ],
  templateUrl: './pu-historik.component.html',
  styleUrl: './pu-historik.component.css'
})
export class PuHistorikComponent {
  puHistorikService = inject(PensionsudbyderHistorikService);

}
