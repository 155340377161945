@if (brugerNotifikationerService.data().length == 0) {
  <div>
    Brugeren abonnerer ikke på nogen notifikationer
  </div>
} @else {
  @for (bn of brugerNotifikationerService.data(); track bn.id) {
    <div>{{ bn.notifikationType.navn }}</div>
  }

}
