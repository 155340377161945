import { Injectable } from '@angular/core';
import { PengeinstitutIndstilling } from './pengeinstitutindstilling.model';
import { ApiRestService } from '@core/api/api.rest.service';

@Injectable({
  providedIn: 'root'
})
export class PengeinstitutterIndstillingerService extends ApiRestService<PengeinstitutIndstilling> {
  constructor() {
    super('/api/admin/pengeinstitutindstillinger');
  }
}
