import { Injectable } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { Organisation } from './organisation.model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService extends ApiRestService<Organisation> {

  constructor() {
    super('/v2/organisationer');
    this.load({ params: { sortBy: 'navn' } }).then();
  }

}
