/// <reference types="@angular/localize" />

import { initAppConfig } from './app/core/app-config';
import { initSentry } from './app/core/sentry';
import { initAngular } from './app/core/angular';

(async () => {
  await initAppConfig();
  initSentry();
  initAngular();
})();
