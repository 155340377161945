<p-table
  #piResultTable
  id="piResultTable"
  [value]="piService.data()"
  [loading]="piService.isLoading()"
  [showGridlines]="true"
  [scrollable]="true"
  scrollHeight="flex"
  stripedRows
  class="p-datatable-sm lp-table"
  selectionMode="single"
  [(selection)]="piService.selectedPi"
  (onRowSelect)="onRowSelect($event)"
  dataKey="id"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="navn">
        Navn
        <p-sortIcon field="navn" />
      </th>
      <th pSortableColumn="regnr" style="width: 120px">
        Regnr
        <p-sortIcon field="regnr" />
      </th>
      <th pSortableColumn="itCentral" style="width: 120px">
        IT Central
        <p-sortIcon field="itCentral" />
      </th>
      <th pSortableColumn="gaeldendeFra" style="width: 140px">
        Gældende fra
        <p-sortIcon field="gaeldendeFra" />
      </th>
      <th pSortableColumn="gaeldendeTil" style="width: 140px">
        Gældende til
        <p-sortIcon field="gaeldendeTil" />
      </th>
      <th pSortableColumn="indstillingerHentesFraItc" style="width: 230px">
        Indstillinger hentes fra ITC
        <p-sortIcon field="indstillingerHentesFraItc" />
      </th>
    </tr>
  </ng-template>
  <ng-template [appTableRow]="piService.data()" pTemplate="body" let-item>
    <tr [pSelectableRow]="item" class="cursor-pointer">
      <td>{{ item.navn }}</td>
      <td>{{ item.regnr }}</td>
      <td>{{ item.itCentral }}</td>
      <td>{{ item.gaeldendeFra | date : "dd-MM-YYYY" }}</td>
      <td>{{ item.gaeldendeTil | date : "dd-MM-YYYY" }}</td>
      <td>
        @if (item.indstillingerHentesFraItc) {
        <i class="pi pi-check"></i>
        }
      </td>
    </tr>
  </ng-template>
</p-table>
