<p-table [value]="piHistorikService.data()"
         stripedRows
         [loading]="piHistorikService.isLoading()"
         class="p-datatable-sm lp-table">
  <ng-template pTemplate="header">
    <tr>
      <th>
        Tidspunkt
      </th>
      <th>
        Bruger
      </th>
      <th>
        Kommentar
      </th>
      <th>
        Ændringer
      </th>
    </tr>
  </ng-template>
  <ng-template [appTableRow]="piHistorikService.data()" pTemplate="body" let-hist>
    <tr>
      <td class="numbers">{{ hist.tidspunkt | date:'dd-MM-YYYY HH:mm' }}</td>
      <td>{{ hist.brugerNavn }}</td>
      <td>{{ hist.kommentar }}</td>
      <td>{{ hist.systemKommentar }}</td>
    </tr>
  </ng-template>
</p-table>
