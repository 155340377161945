import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-manuel-helbred',
    imports: [
        Message
    ],
    templateUrl: './manuel-helbred.component.html',
    styleUrl: './manuel-helbred.component.css'
})
export class ManuelHelbredComponent {

}
