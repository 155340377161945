<app-system-page>
  @if (authStatus() === AuthStatus.UNAUTHENTICATED) {
    <div class="flex gap-2">
      <input type="text" pInputText autofocus [(ngModel)]="username"
             placeholder="Log på med din mailadresse"
             (keyup.enter)="onClickLogin()"
             class="w-80" />
      <p-button label="Login" [disabled]="!usernameIsValid()" (click)="onClickLogin()" />
    </div>
  } @else if (authStatus() === AuthStatus.USERCHECK_UNREGISTERED) {
    <div class="overskrift">Du er ikke registreret som bruger hos Letpension</div>
    <div>
      <p-button label="Tilbage til login" (click)="authService.retryLogin()"></p-button>
    </div>
  } @else if (authService.state().failed) {
    <div class="overskrift">Login mislykkedes</div>
    <div>{{ authStatus() }}</div>
    <div>
      <p-button label="Prøv igen" (click)="authService.retryLogin()"></p-button>
    </div>
  } @else {
    <div class="loader">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    @if (authStatus() !== AuthStatus.UNAUTHENTICATED) {
      <div>
        {{ authStatus() }}
      </div>
    }
  }
</app-system-page>
