<p-table
  id="raadgiverResultTable"
  [value]="raadgiverService.data()"
  [loading]="raadgiverService.isLoading()"
  [showGridlines]="true"
  [scrollable]="true"
  scrollHeight="flex"
  stripedRows
  class="p-datatable-sm lp-table"
  selectionMode="single"
  [(selection)]="raadgiverService.selectedRaadgiver"
  (onRowSelect)="onRowSelect($event)"
  dataKey="id"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="navn">
        Navn
        <p-sortIcon field="navn" />
      </th>
      <th pSortableColumn="brugerId" style="width: 160px">
        Bruger id
        <p-sortIcon field="brugerId" />
      </th>
      <th pSortableColumn="pengeinstitut.navn">
        Pengeinstitut
        <p-sortIcon field="pengeinstitut.navn" />
      </th>
      <th pSortableColumn="pengeinstitut.regnr">
        Regnr
        <p-sortIcon field="pengeinstitut.regnr" />
      </th>
      <th pSortableColumn="inaktivering" style="width: 140px">
        Deaktiveret
        <p-sortIcon field="inaktivering" />
      </th>
      <th pSortableColumn="email" style="width: 200px">
        Email
        <p-sortIcon field="email" />
      </th>
    </tr>
  </ng-template>
  <ng-template
    [appTableRow]="raadgiverService.data()"
    pTemplate="body"
    let-item
  >
    <tr [pSelectableRow]="item" class="cursor-pointer">
      <td>{{ item.navn }}</td>
      <td>{{ item.brugerId }}</td>
      <td>{{ item.pengeinstitut?.navn }}</td>
      <td>{{ item.pengeinstitut?.regnr }}</td>
      <td>{{ item.inaktivering | date : "dd-MM-YYYY" }}</td>
      <td>{{ item.email }}</td>
    </tr>
  </ng-template>
</p-table>
