import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-driftsmeddelelser',
    imports: [
        Message
    ],
    templateUrl: './driftsmeddelelser.component.html',
    styleUrl: './driftsmeddelelser.component.css'
})
export class DriftsmeddelelserComponent {

}
