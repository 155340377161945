<p-table
  id="logResultTable"
  [value]="logService.data()"
  [loading]="logService.isLoading()"
  [showGridlines]="true"
  [scrollable]="true"
  scrollHeight="flex"
  stripedRows
  class="p-datatable-sm lp-table"
  selectionMode="single"
  [(selection)]="logService.selectedLogEntry"
  (onRowSelect)="onRowSelect($event)"
  dataKey="id"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 100px">Initieret af</th>
      <th style="width: 100px">Cpr nr.</th>
      <th style="width: 120px">Regnr</th>
      <th style="width: 120px">IT Central</th>
      <th style="width: 170px">Starttid</th>
      <th style="width: 100px">Tidsforbrug</th>
      <th style="width: 120px">System</th>
      <th>Metode</th>
      <th style="width: 40px">SSB</th>
    </tr>
  </ng-template>
  <ng-template [appTableRow]="logService.data()" pTemplate="body" let-item>
    <tr [pSelectableRow]="item" class="cursor-pointer" [class]="rowClass(item)">
      <td>{{ item.brugernavn || item.raadgiverId }}</td>
      <td>{{ item.cpr }}</td>
      <td>{{ item.regnr }}</td>
      <td>{{ item.itCentral }}</td>
      <td class="numbers text-nowrap">
        {{ item.startTid | date : "dd-MM-YYYY HH:mm:ss" }}
      </td>
      <td class="!text-right">{{ item.tidsforbrug | number }} ms</td>
      <td>{{ item.system }}</td>
      <td>{{ item.metodeNavn }}</td>
      <td>
        @if (item.ssbLogEntryId) {
          <i class="pi pi-check"></i>
        }
      </td>
    </tr>
  </ng-template>
</p-table>
