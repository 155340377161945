import { Injectable } from '@angular/core';
import { ApiSimpleService } from '@core/api/api.simple.service';
import { LogSsbEntry } from './log-ssb-entry.model';

@Injectable({
  providedIn: 'root',
})
export class LogDetailSsbService extends ApiSimpleService<LogSsbEntry> {
  constructor() {
    super('/api/logsearch/ssb');
  }

  loadMessages(id: number) {
    this.load({ params: { id: id } });
  }
}
