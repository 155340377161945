import { Injectable } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { BrugerRolle } from './bruger-rolle.model';

@Injectable({
  providedIn: 'root'
})
export class BrugerRollerService extends ApiRestService<BrugerRolle> {

  constructor() {
    super('/v2/brugerroller');
  }

}
