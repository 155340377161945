import { computed, Injectable } from '@angular/core';
import { ApiSimpleService } from '@core/api/api.simple.service';

@Injectable({
  providedIn: 'root'
})
export class LogSearchMetoderService extends ApiSimpleService<string> {
  constructor() {
    super('/api/logsearch/metodenavne');
    super.load();
  }

  getDropdownData = computed(() => {
    const data = this.data();
    const list: string[] = [];
    data.forEach((item) => {
      if (item.includes('.')) {
        const splitted = item.split('.');
        list.push(splitted[1] + ' - ' + splitted[0]);
      } else {
        list.push(item);
      }
    });
    return list.sort((a, b) => a.localeCompare(b));
  });

}
