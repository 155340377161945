import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { InteractionRequiredAuthError, PublicClientApplication, type SilentRequest } from '@azure/msal-browser';
import * as Sentry from '@sentry/browser';
import { HttpClient } from '@angular/common/http';
import { appConfig } from '../app-config';
import { AuthBruger, AuthState, AuthStatus } from './auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private destroyRef = inject(DestroyRef);
  private httpClient = inject(HttpClient);
  private readonly msalInstance!: PublicClientApplication;
  private _state = signal<AuthState>({
    status: AuthStatus.PENDING,
    failed: false,
    bruger: {
      id: -1,
      rettigheder: [],
      email: '',
      organisationId: -1,
      organisation: {
        type: ''
      }
    },
    accessToken: '',
    username: ''
  });

  state = this._state.asReadonly();

  constructor() {
    this.msalInstance = new PublicClientApplication(appConfig.msalConfig);
    this.msalInstance.initialize().then(() => {
      this.msalInstance
        .handleRedirectPromise()
        .then(this.handleResponse.bind(this))
        .catch((error) => {
          this.setAuthStatus(AuthStatus.FAILED, true);
          throw error;
        });
    });
  }

  hasRettighed(rettighed?: string) {
    if (!rettighed) return true;
    return this._state().bruger.rettigheder.includes(rettighed);
  }

  isLetpensionBruger() {
    return this._state().bruger.organisation.type === 'Letpension';
  }

  getBrugerOrganisationId() {
    return this._state().bruger.organisationId;
  }

  private setAuthStatus(status: AuthStatus, failed?: boolean) {
    this._state.update((state) => ({
      ...state,
      status: status,
      failed: failed || this._state().failed
    }));
  }

  handleResponse() {
    const currentAccounts = this.msalInstance.getAllAccounts();

    if (currentAccounts.length >= 1) {
      const account = currentAccounts[0];

      this._state.update((state) => ({
        ...state,
        status: AuthStatus.AUTHENTICATED,
        account: account
      }));

      if (appConfig.sentry.enabled) {
        Sentry.setUser({
          id: account.localAccountId,
          username: account.name,
          ip_address: '{{auto}}'
        });
      }

      const subscription = this.httpClient
        .get(`${appConfig.apiRoot}/api/brugerauthorization`)
        .subscribe({
          next: (response) => {
            this._state.update((state) => ({
              ...state,
              bruger: response as AuthBruger,
              status: AuthStatus.AUTHORIZED
            }));
            if (appConfig.sentry.enabled) {
              Sentry.setUser({
                id: this._state().bruger.id.toString(),
                username: this._state().bruger.email,
                email: this._state().bruger.email,
                ip_address: '{{auto}}'
              });
            }
          },
          error: (err) => {
            //TODO Handle error getting brugerauthorization
            console.error(err);
            this.setAuthStatus(AuthStatus.UNAUTHENTICATED);
          }
        });
      this.destroyRef.onDestroy(() => subscription.unsubscribe());
    } else {
      this.setAuthStatus(AuthStatus.UNAUTHENTICATED);
    }
  }

  async signIn(username: string) {
    this._state.update((state) => ({
      ...state,
      status: AuthStatus.PENDING,
      username: username
    }));
    const userIsRegistered = await this.checkIfUserIsRegistered(username);

    if (userIsRegistered) {
      this.msalInstance
        .loginRedirect({
          loginHint: username,
          scopes: ['openid', ...appConfig.b2cScopes]
        })
        .catch((error) => {
          console.log('login error');
          console.log(error);
        });
    }
  }

  async retryLogin() {
    if (this.msalInstance.getAllAccounts().length > 0) {
      await this.signOut();
    } else {
      this.setAuthStatus(AuthStatus.UNAUTHENTICATED, false);
    }
  }

  async checkIfUserIsRegistered(username: string) {
    this.setAuthStatus(AuthStatus.USERCHECK);

    const params = new URLSearchParams({
      email: username,
      _cb: new Date().getTime().toString()
    });
    let userExists = 0;
    let response: Response;
    try {
      response = await fetch(
        `${appConfig.apiRoot}/api/brugerauthorization/check?${params}`
      );
      if (!response.ok) {
        this.setAuthStatus(AuthStatus.USERCHECK_FAILED, true);
        Sentry.captureException(
          new Error('Check user response not OK: ' + response.statusText)
        );
        const responseText = await response.text();
        Sentry.captureMessage(responseText);
        console.error(responseText);
        return false;
      }
      const responseText = await response.text();
      userExists = parseInt(responseText);
    } catch (e) {
      this.setAuthStatus(AuthStatus.USERCHECK_NETWORK_FAILED, true);
      console.error(e);
      Sentry.captureException(e);
      return false;
    }

    if (userExists > 0) {
      this.setAuthStatus(AuthStatus.USERCHECK_OK);
      return true;
    }
    this.setAuthStatus(AuthStatus.USERCHECK_UNREGISTERED);
    return false;
  }

  async signOut() {
    try {
      return await this.msalInstance.logoutRedirect({
        account: this._state().account,
        postLogoutRedirectUri: '/'
      });
    } catch (error) {
      console.log('logout error');
      console.log(error);
    }
  }

  getToken() {
    const request: SilentRequest = {
      scopes: [...appConfig.b2cScopes],
      account: this._state().account
    };
    return (
      this.msalInstance
        .acquireTokenSilent(request)
        .then((response) => {
          // In case the response from B2C server has an empty accessToken field
          // throw an error to initiate token acquisition
          if (!response.accessToken || response.accessToken === '') {
            debugger;
            throw new InteractionRequiredAuthError();
          }
          this._state.update((state) => ({
            ...state,
            accessToken: response.accessToken
          }));

          return response;
        })
        // eslint-disable-next-line consistent-return
        .catch((error) => {
          console.log(error);
          console.log('silent token acquisition fails. Sign out??');
          console.log('Should we only sign out if fail during login?');
          // this.signOut().then();

          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return this.msalInstance.acquireTokenRedirect(request);
          } else {
            return this.signOut();
          }
        })
    );
  }
}
