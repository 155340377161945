import { Component, computed, inject } from '@angular/core';
import { TableModule } from 'primeng/table';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { RollerService } from '../roller.service';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-roller-grid',
    imports: [
        TableModule,
        RouterLink,
        TableRowDirective,
        ButtonModule,
        ToolbarModule,
    ],
    templateUrl: './roller-grid.component.html',
    styleUrl: './roller-grid.component.css'
})
export class RollerGridComponent {
  rollerService = inject(RollerService);
  roller = computed(() => this.rollerService.data());
}
