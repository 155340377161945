import { Component, inject } from '@angular/core';
import { RaadgivereService } from './raadgivere.service';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'primeng/tabs';
import { RaadgiverSearchComponent } from './raadgiver-search/raadgiver-search.component';
import { RaadgiverResultComponent } from './raadgiver-result/raadgiver-result.component';
import { RaadgiverDetailComponent } from './raadgiver-detail/raadgiver-detail.component';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-raadgivere',
  imports: [
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    RaadgiverSearchComponent,
    RaadgiverResultComponent,
    RaadgiverDetailComponent,
    ButtonModule
  ],
  templateUrl: './raadgivere.component.html',
  styleUrl: './raadgivere.component.css'
})
export class RaadgivereComponent {
  raadgiverService = inject(RaadgivereService);

  onSearchResultClick() {
    this.raadgiverService.restoreRaadgiverResultScrollPosition();
  }

  onClickOpret() {
    this.raadgiverService.opretRaadgiver();
  }
}
