import { Component, inject } from '@angular/core';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'primeng/tabs';
import { PengeinstitutterService } from './pengeinstitutter.service';
import { PiSearchComponent } from './pi-search/pi-search.component';
import { PiResultComponent } from './pi-result/pi-result.component';
import { PiDetailComponent } from './pi-detail/pi-detail.component';

@Component({
  selector: 'app-pengeinstitutter',
  imports: [
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    PiSearchComponent,
    PiResultComponent,
    PiDetailComponent
  ],
  templateUrl: './pengeinstitutter.component.html',
  styleUrl: './pengeinstitutter.component.css'
})
export class PengeinstitutterComponent {
  piService = inject(PengeinstitutterService);

  onSearchResultClick() {
    this.piService.restorePiResultScrollPosition();
  }
}
