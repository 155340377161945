<h1>Slet revision</h1>
<form [formGroup]="form">
  <div class="control required">
    <label>Cpr</label>
    <input pInputText autofocus formControlName="cpr" />
    @if (cprIsInvalid) {
      <p class="error">Cpr nr. er påkrævet</p>
    }
  </div>
  <div class="control required">
    <label>Letsikring Nr.</label>
    <input pInputText formControlName="letsikringNr" />
    @if (letsikringNrIsInvalid) {
      <p class="error">Letsikring nr. er påkrævet</p>
    }
  </div>
  <div class="control required">
    <label>Revision Nr.</label>
    <input pInputText formControlName="revisionNr" />
    @if (revisionNrIsInvalid) {
      <p class="error">Revision er påkrævet</p>
    }
  </div>
  <div class="flex gap-2 mt-2 mb-4">
    <p-button label="Valider sletning" [disabled]="!form.valid" [loading]="loading()" (click)="sletRevision(0)" />
    <p-button label="Slet revision" [disabled]="!sletningTilladt()" [loading]="loading()" (click)="sletRevision(1)" />
    <p-button label="Nulstil" (click)="fortryd()" outlined />
  </div>
  @if (validationMessage()) {
    <p-message [text]="validationMessage()" severity="error" class="max-w-32" />
  }
  @if (besked()) {
    <p-message [text]="besked()" />
  }
</form>
