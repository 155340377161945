import { Injectable } from '@angular/core';
import { Rettighed } from './rettighed.model';
import { ApiSimpleService } from '@core/api/api.simple.service';

@Injectable({
  providedIn: 'root'
})
export class RettighederService extends ApiSimpleService<Rettighed> {

  constructor() {
    super('/api/RettighederEnum');
    this.load();
  }

}
