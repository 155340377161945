import { Component, inject } from '@angular/core';
import { TableModule } from 'primeng/table';
import { LogService } from '../log.service';
import { LogDetailCallsService } from './log-detail-calls.service';
import { DecimalPipe } from '@angular/common';
import { TableRowDirective } from '@core/primevue/directives/table-row.directive';
import { LogEntry } from '../log.model';

@Component({
  selector: 'app-log-detail-calls',
  imports: [DecimalPipe, TableModule, TableRowDirective],
  templateUrl: './log-detail-calls.component.html',
  styleUrl: './log-detail-calls.component.css',
})
export class LogDetailCallsComponent {
  logService = inject(LogService);
  logDetailCallsService = inject(LogDetailCallsService);

  rowClass(item: LogEntry) {
    const logEntryId = this.logService.selectedLogEntry()?.id;
    let cls = '';
    if (item.id == logEntryId) {
      cls += '!font-bold ';
    }
    if (item.fejlet) {
      cls += '!text-lpred ';
    }
    return cls;
  }

  onRowClick(item: LogEntry) {
    this.logService.selectLogEntry(item);
  }
}
