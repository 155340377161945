import { Component } from '@angular/core';
import { Message } from 'primeng/message';

@Component({
    selector: 'app-batch',
    imports: [
        Message
    ],
    templateUrl: './batch.component.html',
    styleUrl: './batch.component.css'
})
export class BatchComponent {

}
