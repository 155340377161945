import { inject, Injectable } from '@angular/core';
import { ApiResponse } from '@core/api/apiresponse.model';
import { RollerService } from '../roller.service';
import { Rolle } from '../rolle.model';
import { RolleRettighederService } from '../rolle-rettigheder/rolle-rettigheder.service';

@Injectable({
  providedIn: 'root'
})
export class RolleDetailService {
  rollerService = inject(RollerService);
  rolleRettighedService = inject(RolleRettighederService);

  changes: { [key: string]: any } = {};

  async saveRolle(id: number, newData: Rolle) {
    let response: ApiResponse<Rolle>;
    if (id > 0) {
      response = await this.rollerService.update(id, newData);
    } else {
      response = await this.rollerService.create(newData);
    }
    return response;
  }

  saveRolleRettigheder(rolleId: number) {
    const promises = [];
    for (const [rettighed, change] of Object.entries(this.changes)) {
      if (change.action === 'remove') {
        promises.push(this.rolleRettighedService.remove(change.id));
      }
      if (change.action === 'add') {
        promises.push(
          this.rolleRettighedService.create({
            rolleId: rolleId,
            rettighed: rettighed
          })
        );
      }
    }
    this.changes = [];
    return Promise.all(promises);
  }


}

