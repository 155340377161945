import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SystemPagecomponent } from '../system-page/system-pagecomponent';

@Component({
  selector: 'app-access-denied',
  imports: [ButtonModule, SystemPagecomponent],
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.css',
})
export class AccessDeniedComponent {}
