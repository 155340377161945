<div class="flex flex-col h-full overflow-hidden">
  @let item = data(); @if(item){
  <div class="detail-section-header">Detaljer</div>
  <div class="flex gap-4">
    <div>
      <table class="detail-table">
        <tr>
          <td class="detail-label">Id</td>
          <td>{{ item.id }}</td>
        </tr>
        <tr>
          <td class="detail-label">Tidspunkt</td>
          <td>{{ item.logTidspunkt | date : "dd-MM-YYYY HH:mm:ss" }}</td>
        </tr>
        <tr>
          <td class="detail-label">Simulator</td>
          <td>{{ item.simulator }}</td>
        </tr>
        <tr>
          <td class="detail-label">System</td>
          <td>{{ item.systemNavn }}</td>
        </tr>
        <tr>
          <td class="detail-label">Klient</td>
          <td>{{ item.klientUrl }}</td>
        </tr>
      </table>
    </div>
    <div>
      <table class="detail-table">
        <tr>
          <td class="detail-label">Indgaaende URL</td>
          <td>{{ item.indgaaendeUrl }}</td>
        </tr>
        <tr>
          <td class="detail-label">Udgaaende URL</td>
          <td>{{ item.udgaaendeUrl }}</td>
        </tr>
        <tr>
          <td class="detail-label">Request fejlet</td>
          <td>{{ item.requestFejlet }}</td>
        </tr>
        <tr>
          <td class="detail-label">Response fejlet</td>
          <td>{{ item.responseFejlet }}</td>
        </tr>
        <tr>
          <td class="detail-label">Fejlbesked</td>
          <td>{{ item.fejlBesked }}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="detail-section-header mt-4">Indhold</div>
  <div class="flex-1 h-full overflow-hidden flex flex-col gap-4">
    @if(item.eksternIndgaaendeXml || item.eksternUdgaaendeXml){
    <div class="font-bold">INTERN</div>
    }
    <div class="flex-1 flex h-full overflow-hidden gap-4">
      <app-log-ssb-content
        title="Request"
        [content]="item.internIndgaaendeXml"
      />
      <app-log-ssb-content
        title="Response"
        [content]="item.internUdgaaendeXml"
      />
    </div>
    @if(item.eksternIndgaaendeXml || item.eksternUdgaaendeXml){
    <div class="font-bold mt-4">EKSTERN</div>
    <div class="flex-1 flex h-full overflow-hidden gap-4">
      <app-log-ssb-content
        title="Request"
        [content]="item.eksternIndgaaendeXml"
      />
      <app-log-ssb-content
        title="Response"
        [content]="item.eksternUdgaaendeXml"
      />
    </div>
    }
  </div>
  }
</div>
