import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputText } from 'primeng/inputtext';
import { Message } from 'primeng/message';
import { Button } from 'primeng/button';
import { ApiResponse } from '@core/api/apiresponse.model';
import { appConfig } from '@core/app-config';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { validateAllFormControls } from '@core/form-utils';

@Component({
    selector: 'app-slet-revision',
    imports: [
        FormsModule,
        InputText,
        Message,
        ReactiveFormsModule,
        Button
    ],
    templateUrl: './vend-underskrift.component.html',
    styleUrl: './vend-underskrift.component.css'
})
export class VendUnderskriftComponent {
  private httpClient = inject(HttpClient);

  loading = signal(false);
  vendingTilladt = signal(false);
  data = signal<Data | undefined>(undefined);
  validationMessage = signal<string | undefined>(undefined);
  besked = signal('');
  fejlbesked = signal('');

  form = new FormGroup({
    cpr: new FormControl('', {
      validators: [Validators.required]
    }),
    dokumentId: new FormControl('', {
      validators: [Validators.required]
    })
  });

  get cprIsInvalid() {
    return (
      this.form.controls.cpr.touched &&
      this.form.controls.cpr.dirty &&
      this.form.controls.cpr.invalid
    );
  }

  get dokumentIdIsInvalid() {
    return (
      this.form.controls.dokumentId.touched &&
      this.form.controls.dokumentId.dirty &&
      this.form.controls.dokumentId.invalid
    );
  }

  async vendTilFysiskUnderskrift(udfoerVending = 0) {
    this.validationMessage.set(undefined);
    validateAllFormControls(this.form);
    if (this.form.invalid) {
      return;
    }

    const submitData = {
      ...this.form.value as Data,
      udfoerSletning: udfoerVending,
      cpr: this.form.value.cpr!.replaceAll('-', '')
    };

    this.besked.set('');
    this.loading.set(true);
    try {
      const source = this.httpClient.get<ApiResponse<any>>(`${appConfig.apiRoot}/api/support/vendtilfysiskunderskrift`, { params: submitData });
      const response = await firstValueFrom(source);

      if (!response.success) {
        this.vendingTilladt.set(false);
        this.validationMessage.set(response.message);
      } else {
        if (udfoerVending === 1) {
          this.vendingTilladt.set(false);
        } else {
          this.vendingTilladt.set(true);
        }
        if (response.message) {
          this.besked.set(response.message);
        }
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        this.validationMessage.set(e.message);
      }
    } finally {
      this.loading.set(false);
    }
  }

  fortryd() {
    this.form.reset();
  }


}

interface Data {
  udfoerSletning: number;
  cpr: string;
  dokumentId: string;
}
