import { Injectable } from '@angular/core';
import { ApiRestService } from '@core/api/api.rest.service';
import { RolleRettighed } from './rolle-rettighed.model';

@Injectable({
  providedIn: 'root'
})
export class RolleRettighederService extends ApiRestService<RolleRettighed> {

  constructor() {
    super('/v2/rollerettigheder');
  }

}
