import { Component, computed, input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { BrugereGridComponent } from '../brugere-grid/brugere-grid.component';
import { BrugerDetailComponent } from '../bruger-detail/bruger-detail.component';

@Component({
  selector: 'app-brugere',
  imports: [TableModule, BrugereGridComponent, BrugerDetailComponent],
  templateUrl: './brugere-main.component.html',
  styleUrl: './brugere-main.component.css',
})
export class BrugereMainComponent {
  id = input<string>();
  brugerId = computed(() => Number(this.id()));
}
