@let raadgiver = raadgiverService.selectedRaadgiver();
<p-scroll-panel styleClass="h-full">
  <div [formGroup]="form">
    <div class="flex" style="gap: 40px">
      <div style="width: 400px">
        <div class="control required">
          <label>Fornavn</label>
          @if (allowEdit()) {
          <input pInputText formControlName="fornavn" />
          @if (form.controls['fornavn'].errors &&
          form.controls['fornavn'].touched) {
          <p class="error">Feltet skal udfyldes</p>
          } } @else {
          <div>{{ raadgiver?.fornavn }}</div>
          }
        </div>
        <div class="control">
          <label>Mellemnavne</label>
          @if (allowEdit()) {
          <input pInputText formControlName="mellemnavne" />
          } @else {
          <div>{{ raadgiver?.mellemnavne }}</div>
          }
        </div>
        <div class="control">
          <label>Efternavn</label>
          @if (allowEdit()) {
          <input pInputText formControlName="efternavn" />
          } @else {
          <div>{{ raadgiver?.efternavn }}</div>
          }
        </div>
        <div class="control required">
          <label>Bruger id</label>
          @if (allowEdit()) {
          <input pInputText formControlName="brugerId" />
          @if (form.controls['brugerId'].errors &&
          form.controls['brugerId'].touched) {
          <p class="error">Feltet skal udfyldes</p>
          } } @else {
          <div>{{ raadgiver?.brugerId }}</div>
          }
        </div>
        <div class="control">
          <label>Pegeinstitut</label>
          @if (allowEdit()) {
          <app-pengeinstitut-select formControlName="pengeinstitutId" />
          } @else {
          <div>{{ raadgiver?.pengeinstitut?.navn }}</div>
          }
        </div>
        <div class="control required">
          <label>Gældende fra</label>
          @if (allowEdit()) {
          <p-datePicker
            formControlName="oprettet"
            [showIcon]="true"
            [hourFormat]="'24'"
          />
          @if (form.controls['oprettet'].errors &&
          form.controls['oprettet'].touched) {
          <p class="error">Feltet skal udfyldes</p>
          } } @else {
          <div>{{ raadgiver?.oprettet }}</div>
          }
        </div>
        <div class="control">
          <label>Gældende til</label>
          @if (allowEdit()) {
          <p-datePicker
            formControlName="inaktivering"
            appendTo="body"
            [showIcon]="true"
            [hourFormat]="'24'"
          />
          } @else {
          <div>{{ raadgiver?.inaktivering }}</div>
          }
        </div>
        <div class="control flex">
          <label style="width: 200px">Formuerådgiver</label>
          @if (allowEdit()) {
          <p-checkbox [binary]="true" formControlName="formueraadgiver" />
          } @else {
          <div>{{ raadgiver?.formueraadgiver | boolean }}</div>
          }
        </div>
        <div class="control flex">
          <label style="width: 200px">LTS rådgiver</label>
          @if (allowEdit()) {
          <p-checkbox
            [binary]="true"
            formControlName="letsikringTilSelvstaendigRaadgiver"
          />
          } @else {
          <div>
            {{ raadgiver?.letsikringTilSelvstaendigRaadgiver | boolean }}
          </div>
          }
        </div>
        <div class="control flex">
          <label style="width: 200px">FAL rettighed</label>
          @if (allowEdit()) {
          <p-checkbox [binary]="true" formControlName="falRettighed" />
          } @else {
          <div>{{ raadgiver?.falRettighed | boolean }}</div>
          }
        </div>
      </div>
      <div style="width: 400px">
        <div formGroupName="adresse">
          <div class="control">
            <label>C/O</label>
            @if (allowEdit()) {
            <input pInputText formControlName="conavn" />
            } @else {
            <div>{{ raadgiver?.adresse?.conavn }}</div>
            }
          </div>
          <div class="control">
            <label>Vejnavn</label>
            @if (allowEdit()) {
            <input pInputText formControlName="vejnavn" />
            } @else {
            <div>{{ raadgiver?.adresse?.vejnavn }}</div>
            }
          </div>
          <div class="control">
            <label>Vejnummer</label>
            @if (allowEdit()) {
            <input pInputText formControlName="vejnr" />
            } @else {
            <div>{{ raadgiver?.adresse?.vejnr }}</div>
            }
          </div>
          <div class="control">
            <label>Sted</label>
            @if (allowEdit()) {
            <input pInputText formControlName="sted" />
            } @else {
            <div>{{ raadgiver?.adresse?.sted }}</div>
            }
          </div>
          <div class="control">
            <label>Postnummer</label>
            @if (allowEdit()) {
            <input pInputText formControlName="postnr" />
            } @else {
            <div>{{ raadgiver?.adresse?.postnr }}</div>
            }
          </div>
          <div class="control">
            <label>Bynavn</label>
            @if (allowEdit()) {
            <input pInputText formControlName="bynavn" />
            } @else {
            <div>{{ raadgiver?.adresse?.bynavn }}</div>
            }
          </div>
        </div>
        <div class="control required">
          <label>Email</label>
          @if (allowEdit()) {
          <input pInputText formControlName="email" />
          @if (form.controls['email'].errors && form.controls['email'].touched)
          {
          <p class="error">Feltet skal udfyldes</p>
          } } @else {
          <div>{{ raadgiver?.email }}</div>
          }
        </div>
        <div class="control required">
          <label>Telefonnummer</label>
          @if (allowEdit()) {
          <input pInputText formControlName="telefonNummer" />
          @if (form.controls['telefonNummer'].errors &&
          form.controls['telefonNummer'].touched) {
          <p class="error">Feltet skal udfyldes</p>
          } } @else {
          <div>{{ raadgiver?.telefonNummer }}</div>
          }
        </div>
      </div>
    </div>

    @if (allowEdit()) {
    <p-button
      label="Gem ændringer"
      icon="pi pi-save"
      (click)="onClickSave()"
      [disabled]="!form.valid"
    />
    } @if (infoMessage()) {
    <div class="mt-2 p-2">
      <p-message [text]="infoMessage()" severity="info" />
    </div>
    }
  </div>
</p-scroll-panel>
