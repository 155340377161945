import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Button } from 'primeng/button';
import { InputText } from 'primeng/inputtext';
import { PengeinstitutterService } from '../pengeinstitutter.service';
import { PiSearchParams } from './pi-search-params.model';
import { Message } from 'primeng/message';
import { Select } from 'primeng/select';

@Component({
  selector: 'app-pi-search',
  imports: [Button, ReactiveFormsModule, InputText, Message, Select],
  templateUrl: './pi-search.component.html',
  styleUrl: './pi-search.component.css'
})
export class PiSearchComponent {
  piService = inject(PengeinstitutterService);
  validationMessage = signal('');

  itcOptions = ['BD', 'BEC', 'SDC'];

  form = new FormGroup({
    navn: new FormControl(),
    regnr: new FormControl(),
    itCentral: new FormControl()
  });

  async onClickSearch() {
    this.validationMessage.set('');
    this.piService.piSearchParams = {
      ...(this.form.value as PiSearchParams)
    };

    await this.piService.searchPi();
    if (this.piService.data().length == 0) {
      this.validationMessage.set('Ingen hits');
    }
  }

  onClickReset() {
    this.form.reset();
  }
}
