import { Component, computed, inject } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

@Component({
    selector: 'app-user-profile',
    imports: [
        ButtonModule,
        DividerModule
    ],
    templateUrl: './user-profile.component.html',
    styleUrl: './user-profile.component.css'
})
export class UserProfileComponent {
  authService = inject(AuthService);
  currentUser = computed(() => this.authService.state().bruger);

  async onClickLogout(){
    console.log('onClickLogout');
    await this.authService.signOut();
  }
}
