export enum RettighedsType {
  DeveloperMode = 'DeveloperMode',

  AppAdmin = 'AppAdmin',
  AdminBruger = 'AdminBruger',
  AdminRolle = 'AdminRolle',
  AdminLog = 'AdminLog',
  AdminSupportSletRevision = 'AdminSupportSletRevision',
  AdminSupportVendTilFysiskUnderskrift = 'AdminSupportVendTilFysiskUnderskrift',

  AdminPengeinstitutter = 'AdminPengeinstitutter',
  AdminPengeinstitutterSkriv = 'AdminPengeinstitutterSkriv',
  AdminPensionsudbydere = 'AdminPensionsudbydere',
  AdminPensionsudbydereSkriv = 'AdminPensionsudbydereSkriv',
  AdminRaadgivere = 'AdminRaadgivere',
  AdminRaadgivereSkriv = 'AdminRaadgivereSkriv',
  AdminDokumenter = 'AdminDokumenter',
  AdminKunder = 'AdminKunder',
  AdminOpkraevningslister = 'AdminOpkraevningslister',
  AdminProduktlister = 'AdminProduktlister',
  AdminRisikoopkraevning = 'AdminRisikoopkraevning',
  AdminBatch = 'AdminBatch',
  AdminDriftsmeddelelser = 'AdminDriftsmeddelelser',
  AdminSsbKonfiguration = 'AdminSsbKonfiguration',
  AdminFiler = 'AdminFiler',
  AdminManuelHelbred = 'AdminManuelHelbred',
  AdminLetpensionDk = 'AdminLetpensionDk',
  AdminLetregister = 'AdminLetregister',


}
