@let i = indstillinger();
<p-scroll-panel styleClass="h-full">
  <table class="detail-table bordered">
    <tr>
      <th></th>
      <th>Primær</th>
      <th>Subsidiær</th>
    </tr>
    <tr>
      <td style="width:200px">Boet anvendelse</td>
      <td>{{ i.boetBegunstigelseAnvendelse | boolean }}</td>
      <td>{{ i.subsidiaerBoetAnvendelse | boolean }}</td>
    </tr>
    <tr>
      <td>Persongruppe anvendelse</td>
      <td>{{ i.persongruppeAnvendelse | boolean }}</td>
      <td>{{ i.subsidiaerBegunstigelsePersongruppe != 'AnvendesIkke' | boolean }}</td>
    </tr>
    <tr>
      <td>Subsidiær navngiven</td>
      <td>{{ i.subsidiaerBegunstigelseNavngivenBegunstiget }}</td>
      <td></td>
    </tr>
    <tr>
      <td>Subsidiær persongruppe</td>
      <td>{{ i.subsidiaerBegunstigelsePersongruppe }}</td>
      <td></td>
    </tr>
    <tr>
      <td class="detail-label">Navngiven</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Navngiven anvendelse</td>
      <td>{{ i.navngivenBegunstigetAnvendelse | boolean }}</td>
      <td>{{ i.subsidiaerBegunstigelseNavngivenBegunstiget != 'AnvendesIkke' | boolean }}</td>
    </tr>
    <tr>
      <td>Antal decimaler</td>
      <td>{{ i.primaerNavngivenBegunstigelseIndstillinger?.antalDecimaler }}</td>
      <td>{{ i.subsidiaerNavngivenBegunstigelseIndstillinger?.antalDecimaler }}</td>
    </tr>
    <tr>
      <td>CPR-nummer anvendelse decimaler</td>
      <td>{{ i.primaerNavngivenBegunstigelseIndstillinger?.cprNummerAnvendelse }}</td>
      <td>{{ i.subsidiaerNavngivenBegunstigelseIndstillinger?.cprNummerAnvendelse }}</td>
    </tr>
    <tr>
      <td>Flere navngivne</td>
      <td>{{ i.primaerNavngivenBegunstigelseIndstillinger?.flereNavngivneBegunstigede | boolean }}</td>
      <td>{{ i.subsidiaerNavngivenBegunstigelseIndstillinger?.flereNavngivneBegunstigede | boolean }}</td>
    </tr>
    <tr>
      <td>Max antal tegn navn</td>
      <td>{{ i.primaerNavngivenBegunstigelseIndstillinger?.maksAntalTegnNavn }}</td>
      <td>{{ i.subsidiaerNavngivenBegunstigelseIndstillinger?.maksAntalTegnNavn }}</td>
    </tr>
    <tr>
      <td>Navngivne hentes fra ITC</td>
      <td>{{ i.primaerNavngivenBegunstigelseIndstillinger?.navngivneBegunstigedeHentesFraItc | boolean }}</td>
      <td>{{ i.subsidiaerNavngivenBegunstigelseIndstillinger?.navngivneBegunstigedeHentesFraItc | boolean }}</td>
    </tr>
    <tr>
      <td>Uigenkaldelig anvendelse</td>
      <td>{{ i.primaerNavngivenBegunstigelseIndstillinger?.uigenkaldeligBegunstigetAnvendelse | boolean }}</td>
      <td>{{ i.subsidiaerNavngivenBegunstigelseIndstillinger?.uigenkaldeligBegunstigetAnvendelse | boolean }}</td>
    </tr>
    <tr>
      <td class="detail-label">Båndlæggelse</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Båndlæggelse anvendelse</td>
      <td>{{ i.primaerNavngivenBegunstigelseIndstillinger?.baandlaeggelseAnvendelse | boolean }}</td>
      <td>{{ i.subsidiaerNavngivenBegunstigelseIndstillinger?.baandlaeggelseAnvendelse | boolean }}</td>
    </tr>
    <tr>
      <td class="detail-label">Særeje</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Særeje anvendelse</td>
      <td>{{ i.primaerNavngivenBegunstigelseIndstillinger?.saerejeAnvendelse | boolean }}</td>
      <td>{{ i.subsidiaerNavngivenBegunstigelseIndstillinger?.saerejeAnvendelse | boolean }}</td>
    </tr>
    <tr>
      <td class="detail-label">Erklæring modtaget</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Erklæring modtaget</td>
      <td>{{ i.erklaeringModtagetAnvendelse | boolean }}</td>
      <td></td>
    </tr>
    <tr>
      <td>Uigenkaldelig anvendelse</td>
      <td>{{ i.erklaeringModtagetUigenkaldeligBegunstigetAnvendelse | boolean }}</td>
      <td></td>
    </tr>
  </table>
</p-scroll-panel>
